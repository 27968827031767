import { toastr } from 'react-redux-toastr';
import { crud as crudApi, handleCommonApiErrors } from '../api';
import * as UserApi from '../api/user';

// action types
export const types = {
  CRUD_LIST_REQUEST: 'REGISTRATION/LIST_REQUEST',
  CRUD_LIST_SUCCESS: 'REGISTRATION/LIST_SUCCESS',
  CRUD_LIST_ERROR: 'REGISTRATION/LIST_ERROR',

  CRUD_READ_REQUEST: 'REGISTRATION/READ_REQUEST',
  CRUD_READ_SUCCESS: 'REGISTRATION/READ_SUCCESS',
  CRUD_READ_ERROR: 'REGISTRATION/READ_ERROR',

  CRUD_UPDATE_REQUEST: 'REGISTRATION/UPDATE_REQUEST',
  CRUD_UPDATE_SUCCESS: 'REGISTRATION/UPDATE_SUCCESS',
  CRUD_UPDATE_ERROR: 'REGISTRATION/UPDATE_ERROR',

  CRUD_DELETE_REQUEST: 'REGISTRATION/DELETE_REQUEST',
  CRUD_DELETE_SUCCESS: 'REGISTRATION/DELETE_SUCCESS',
  CRUD_DELETE_ERROR: 'REGISTRATION/DELETE_ERROR',

  CRUD_SIGNUP_REQUEST: 'REGISTRATION/SIGNUP_REQUEST',
  CRUD_SIGNUP_SUCCESS: 'REGISTRATION/SIGNUP_SUCCESS',
  CRUD_SIGNUP_UPDATE_SUCCESS: 'REGISTRATION/SIGNUP_UPDATE_SUCCESS',
  CRUD_SIGNUP_ERROR: 'REGISTRATION/SIGNUP_ERROR',

  // course
  GET_COURSES_REQUEST: 'REGISTRATION/GET_COURSES_REQUEST',
  GET_COURSES_SUCCESS: 'REGISTRATION/GET_COURSES_SUCCESS',
  GET_COURSES_ERROR: 'REGISTRATION/GET_COURSES_ERROR',
  COURSE_SELECTED: 'REGISTRATION/COURSE_SELECTED',
  CORPORATE_COURSE_SELECTED: 'REGISTRATION/CORPORATE_COURSE_SELECTED',
  NEXT_SELECTED: 'REGISTRATION/NEXT_SELECTED',
  SET_COMPLETED: 'REGISTRATION/SET_COMPLETED',

  CHOOSE_COURSE_REQUEST: 'REGISTRATION/CHOOSE_COURSE_REQUEST',
  CHOOSE_COURSE_SUCCESS: 'REGISTRATION/CHOOSE_COURSE_SUCCESS',
  CHOOSE_COURSE_ERROR: 'REGISTRATION/CHOOSE_COURSE_ERROR',

  // payment plan
  CHANGE_PAYMENT_PLAN: 'REGISTRATION/CHANGE_PAYMENT_PLAN',
  SELECT_MILESTONE: 'REGISTRATION/SELECT_MILESTONE',
  SELECT_CORPORATE_MILESTONE: 'REGISTRATION/SELECT_CORPORATE_MILESTONE',
  CONTINUE_TO_PAYMENT: 'REGISTRATION/CONTINUE_TO_PAYMENT',

  // payment
  PAYMENT_INITIATE_REQUEST: 'REGISTRATION/PAYMENT_REQUEST',
  PAYMENT_INITIATE_SUCCESS: 'REGISTRATION/PAYMENT_INITIATE_SUCCESS',
  PAYMENT_INITIATE_ERROR: 'REGISTRATION/PAYMENT_INITIATE_ERROR',

  // payment
  PAYMENT_COMPLETE_REQUEST: 'REGISTRATION/PAYMENT_COMPLETE_REQUEST',
  PAYMENT_COMPLETE_SUCCESS: 'REGISTRATION/PAYMENT_COMPLETE_SUCCESS',
  PAYMENT_COMPLETE_ERROR: 'REGISTRATION/PAYMENT_COMPLETE_ERROR',

  SELECT_PAYMENT_METHOD: 'REGISTRATION/SELECT_PAYMENT_METHOD',

  CLEAR_DATA: 'REGISTRATION/CLEAR_DATA',

  FETCH_UPDATED_REQUEST: 'AUTH/FETCH_UPDATED_REQUEST',
  FETCH_UPDATED_SUCCESS: 'AUTH/FETCH_UPDATED_SUCCESS',
  FETCH_UPDATED_ERROR: 'AUTH/FETCH_UPDATED_ERROR',

  INVOICE_SELECTED: 'REGISTRATION/INVOICE_SELECTED',
  LUMPSUM_INVOICE_SELECTED: 'REGISTRATION/LUMPSUM_INVOICE_SELECTED',
  // PROMOCODE
  PROMOCODE_INITIATE_REQUEST: 'REGISTRATION/PROMOCODE_INITIATE_REQUEST',
  PROMOCODE_INITIATE_SUCCESS: 'REGISTRATION/PROMOCODE_INITIATE_SUCCESS',
  PROMOCODE_INITIATE_ERROR: 'REGISTRATION/PROMOCODE_INITIATE_ERROR',

  // PROMOCODE
  APPLY_PROMOCODE_INITIATE_REQUEST:
    'REGISTRATION/APPLY_PROMOCODE_INITIATE_REQUEST',
  APPLY_PROMOCODE_INITIATE_SUCCESS:
    'REGISTRATION/APPLY_PROMOCODE_INITIATE_SUCCESS',
  APPLY_PROMOCODE_INITIATE_ERROR: 'REGISTRATION/APPLY_PROMOCODE_INITIATE_ERROR',
  APPLY_PROMOCODE_CLOSE: 'REGISTRATION/APPLY_PROMOCODE_CLOSE',

  // EID
  READ_EID_REQUEST: 'REGISTRATION/READ_EID_REQUEST',
  READ_EID_SUCCESS: 'REGISTRATION/READ_EID_SUCCESS',
  READ_EID_ERROR: 'REGISTRATION/READ_EID_ERROR',

  // REFERRAL
  HAS_REFERRAL: 'REGISTRATION/HAS_REFERRAL',
  REFERRAL_INITIATE_REQUEST: 'REGISTRATION/REFERRAL_INITIATE_REQUEST',
  REFERRAL_INITIATE_SUCCESS: 'REGISTRATION/REFERRAL_INITIATE_SUCCESS',
  REFERRAL_INITIATE_ERROR: 'REGISTRATION/REFERRAL_INITIATE_ERROR',
  APPLY_REFERRAL_INITIATE_REQUEST:
    'REGISTRATION/APPLY_REFERRAL_INITIATE_REQUEST',
  APPLY_REFERRAL_INITIATE_SUCCESS:
    'REGISTRATION/APPLY_REFERRAL_INITIATE_SUCCESS',
  APPLY_REFERRAL_INITIATE_ERROR: 'REGISTRATION/APPLY_REFERRAL_INITIATE_ERROR',
  APPLY_REFERRAL_CLOSE: 'REGISTRATION/APPLY_REFERRAL_CLOSE',

  UPDATE_TABBY_CHARGE: 'REGISTRATION/UPDATE_TABBY_CHARGE',

  FETCH_ENQUIRY_DETAILS_REQUEST: 'REGISTRATION/FETCH_ENQUIRY_DETAILS_REQUEST',
  FETCH_ENQUIRY_DETAILS_SUCCESS: 'REGISTRATION/FETCH_ENQUIRY_DETAILS_SUCCESS',
  FETCH_ENQUIRY_DETAILS_ERROR: 'REGISTRATION/FETCH_ENQUIRY_DETAILS_ERROR',
};

export const registrationStages = {
  registration: 'Registration',
  profileDetails: 'Profile Details',
  courseSelection: 'Course Selection',
  initiatePayment: 'Initiate Payment',
  completePayment: 'Complete Payment',
  finished: 'Finished',
};
export const originalRegistrationStages = {
  profileDetails: 'personalDetails',
  courseSelection: 'courseDetails',
  finished: 'homeScreen',
};

export const paymentModes = {
  online: 'Online',
  fdcCenter: `At ${process.env.REACT_APP_COMPANYCODE} Center`,
};

export const paymentPlans = {
  fullPayment: 'Full Payment',
  stagePayment: 'Stage Payment',
};

const summaryMapReduce = {
  initial: {
    amountRemaining: 0,
    noTaxableAmount: 0,
    // quantity: 0,
    roundOff: 0,
    taxableAmount: 0,
    totalAmount: 0,
    totalAmountPaid: 0,
    totalDiscount: 0,
    totalTax: 0,
    totalTaxPaid: 0,
    drivingCenter: {
      pricePerQuantity: 0,
      taxPerQuantity: 0,
      totalAmount: 0,
      totalAmountPaid: 0,
      totalDiscount: 0,
      totalTax: 0,
      totalTaxPaid: 0,
    },
    rta: {
      pricePerQuantity: 0,
      taxPerQuantity: 0,
      totalAmount: 0,
      totalAmountPaid: 0,
      totalDiscount: 0,
      totalTax: 0,
      totalTaxPaid: 0,
    },
  },
  reduce: (prev, current) => {
    return {
      amountRemaining: prev.amountRemaining + current.amount.amountRemaining,
      noTaxableAmount: prev.noTaxableAmount + current.amount.noTaxableAmount,
      // quantity: prev.quantity + current.amount.quantity,
      roundOff: prev.roundOff + current.amount.roundOff,
      taxableAmount: prev.taxableAmount + current.amount.taxableAmount,
      totalAmount: prev.totalAmount + current.amount.totalAmount,
      totalAmountPaid: prev.totalAmountPaid + current.amount.totalAmountPaid,
      totalDiscount: prev.totalDiscount + current.amount.totalDiscount,
      totalTax: prev.totalTax + current.amount.totalTax,
      totalTaxPaid: prev.totalTaxPaid + current.amount.totalTaxPaid,
      drivingCenter: {
        pricePerQuantity:
          prev.drivingCenter.pricePerQuantity +
          current.amount.drivingCenter.pricePerQuantity,
        taxPerQuantity:
          prev.drivingCenter.taxPerQuantity +
          current.amount.drivingCenter.taxPerQuantity,
        totalAmount:
          prev.drivingCenter.totalAmount +
          current.amount.drivingCenter.totalAmount,
        totalAmountPaid:
          prev.drivingCenter.totalAmountPaid +
          current.amount.drivingCenter.totalAmountPaid,
        totalDiscount:
          prev.drivingCenter.totalDiscount +
          current.amount.drivingCenter.totalDiscount,
        totalTax:
          prev.drivingCenter.totalTax + current.amount.drivingCenter.totalTax,
        totalTaxPaid:
          prev.drivingCenter.totalTaxPaid +
          current.amount.drivingCenter.totalTaxPaid,
      },
      rta: {
        pricePerQuantity:
          prev.rta.pricePerQuantity + current.amount.rta.pricePerQuantity,
        taxPerQuantity:
          prev.rta.taxPerQuantity + current.amount.rta.taxPerQuantity,
        totalAmount: prev.rta.totalAmount + current.amount.rta.totalAmount,
        totalAmountPaid:
          prev.rta.totalAmountPaid + current.amount.rta.totalAmountPaid,
        totalDiscount:
          prev.rta.totalDiscount + current.amount.rta.totalDiscount,
        totalTax: prev.rta.totalTax + current.amount.rta.totalTax,
        totalTaxPaid: prev.rta.totalTaxPaid + current.amount.rta.totalTaxPaid,
      },
    };
  },
};

// initial state
const initialState = {
  isLoading: false,
  list: [],
  listCount: 0,
  record: {},
  isCloned: false,
  paymentMethod: 'cash',
  showCardInput: false,
  showTabbyCardType: false,
  errorMessage: '',
};

// reducer
// eslint-disable-next-line complexity
export default (state = initialState, action) => {
  switch (action.type) {
    // list
    case types.CRUD_LIST_REQUEST: {
      const isNewList = action.currentPage === 1;
      if (isNewList) return { ...initialState, isLoading: true };
      return { ...state, isLoading: true };
    }
    case types.CRUD_LIST_SUCCESS: {
      const isNewList = action.currentPage === 1;
      return {
        isLoading: false,
        resource: action.resource,
        list: isNewList ? [...action.data] : [...state.list, ...action.data],
        listCount: action.count,
      };
    }

    case types.CRUD_LIST_ERROR:
      return { ...state, isLoading: false, list: [], listCount: null };

    // read
    case types.CRUD_READ_REQUEST:
      return { ...state, isLoading: true };

    case types.CRUD_READ_SUCCESS:
      return {
        ...state,
        isLoading: false,
        record: action.data,
      };

    case types.CRUD_READ_ERROR:
      return { ...state, record: {}, isLoading: false };

    // update
    case types.CRUD_UPDATE_REQUEST:
      return { ...state, isLoading: true };

    case types.CRUD_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        record: action.data,
      };

    case types.CRUD_UPDATE_ERROR:
      return { ...state, isLoading: false };

    // delete
    case types.CRUD_DELETE_REQUEST:
      return { ...state, isLoading: true };

    case types.CRUD_DELETE_SUCCESS: {
      const indexOfRecord = state.list.indexOf(action.record);
      // console.log('indexOfRecord: ', indexOfRecord);
      const newListOfRecords = [...state.list];
      newListOfRecords.splice(indexOfRecord, 1);
      return {
        ...state,
        list: [...newListOfRecords],
        isLoading: false,
        record: {},
      };
    }
    case types.CRUD_DELETE_ERROR:
      return { ...state, isLoading: false };

    // clone
    case types.CRUD_CLONE_REQUEST:
      return { ...state, isLoading: true };

    case types.CRUD_CLONE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isCloned: true,
        record: action.data,
      };
    case types.CRUD_CLONE_ERROR:
      return { ...state, isLoading: false };

    // push
    case types.CRUD_PUSH_REQUEST:
      return { ...state, isLoading: true };

    case types.CRUD_PUSH_SUCCESS: {
      const indexOfRecord = state.list.indexOf(action.record);
      // console.log('indexOfRecord: ', indexOfRecord);
      const newListOfRecords = [...state.list];
      newListOfRecords.splice(indexOfRecord, 1);
      return {
        ...state,
        list: [...newListOfRecords],
        isLoading: false,
        record: {},
      };
    }
    case types.CRUD_PUSH_ERROR:
      return { ...state, isLoading: false };

    // sign up
    case types.CRUD_SIGNUP_REQUEST:
      return { ...state, isLoading: true };

    case types.CRUD_SIGNUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        // record: action.data,
        verify: action.data,
      };

    case types.CRUD_SIGNUP_ERROR:
      return { ...state, isLoading: false };
    case types.CRUD_SIGNUP_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        // record: action.data,
        verify: {
          user: { ...action.data.userRecord, id: action.data.userRecord._id },
          token: action.token,
        },
        token: action.token,
        // hasCourse: false,
        // course: null,
        // courseType: null,
        isCompleted: false,
        blockIndex: 0,
        // currentStage: '',
      };

    // courses
    case types.GET_COURSES_REQUEST:
      return { ...state, isLoading: true, courses: null };
    case types.GET_COURSES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        courses: action.data.courses,
        hasCourse: action.data.hasCourse,
      };
    case types.GET_COURSES_ERROR:
      return { ...state, isLoading: false };

    case types.COURSE_SELECTED: {
      if (
        action.data.course === state.course &&
        action.data.courseType === state.courseType
      ) {
        return {
          ...state,
          isLoading: true,
          summary,
          currentStage: registrationStages.initiatePayment,
          referralList: action.data.referralList,
          referralCode: action.data.referralCode,
          referralAmount: action.data.referralAmount,
          virtualTabbyPercentage: action.data?.virtualTabbyPercentage,
          otherTabbyPercentage: action.data?.otherTabbyPercentage,
          tabbyoption: action?.data?.tabbyoption,
        };
      }
      const course = formatCourse(action.data.course);
      const { courseType } = action.data;
      const { milestones } = course;
      milestones.sort((a, b) => a.level - b.level);
      const summary = getSummaryFromMilestones(milestones);
      const promotion =
        course.amount &&
        course.amount.promotions &&
        course.amount.promotions.length > 0
          ? course.amount.promotions[0]
          : null;

      const milestonesForStage = [milestones[0]];
      const nextIndex = 1;

      for (let i = nextIndex; i < milestones.length; i += 1) {
        const courseMilestone = milestones[i];
        if (
          !courseMilestone ||
          courseMilestone.amount.totalAmountWithDiscount > 0
        ) {
          break;
        }
        milestonesForStage.push(courseMilestone);
      }
      return {
        ...state,
        isLoading: false,
        summary,
        course,
        courseType,
        promotion,
        stagePayment: {
          ...state.stagePayment,
          milestones: milestonesForStage,
          summary: getSummaryFromMilestones(milestonesForStage),
        },
        fullPayment: {
          summary,
        },
        paymentPlan: paymentPlans.fullPayment,
        currentStage: registrationStages.initiatePayment,
        tokenBalanceAmount: action.data.tokenBalanceAmount,
        referralList: action.data.referralList,
        referralCode: action.data.referralCode,
        referralAmount: action.data.referralAmount,
        virtualTabbyPercentage: action.data?.virtualTabbyPercentage,
        otherTabbyPercentage: action.data?.otherTabbyPercentage,
        tabbyoption: action.data?.tabbyoption,
      };
    }

    case types.CORPORATE_COURSE_SELECTED: {
      if (
        action.data.course === state.course &&
        action.data.courseType === state.courseType
      ) {
        return {
          ...state,
          isLoading: false,
          summary,
          currentStage: registrationStages.initiatePayment,
        };
      }

      const course = formatCourse(action.data.course);
      const { courseType } = action.data;

      const { corporateMilestones, userMilestones } = action.data;

      corporateMilestones.sort((a, b) => a.level - b.level);
      const summary = getSummaryFromMilestones(corporateMilestones);

      const promotion =
        course?.amount &&
        course?.amount?.promotions &&
        course?.amount?.promotions?.length > 0
          ? course?.amount?.promotions?.[0]
          : null;

      const milestonesForStage =
        userMilestones && userMilestones.length > 0 ? [userMilestones[0]] : [];
      const nextIndex = 1;

      for (let i = nextIndex; i < userMilestones.length; i += 1) {
        const courseMilestone = userMilestones[i];

        if (
          !courseMilestone ||
          courseMilestone.amount.totalAmountWithDiscount > 0
        ) {
          break;
        }
        courseMilestone.level = i;
        milestonesForStage.push(courseMilestone);
      }

      return {
        ...state,
        isLoading: false,
        totalDiscount: summary.totalDiscount,
        summary: null,
        course,
        courseType,
        promotion,
        corporatePayment: {
          ...state.corporatePayment,
          milestones: corporateMilestones,
          summary,
        },
        userPayment: {
          ...state.userPayment,
          milestones: userMilestones,
          // summary: getSummaryFromMilestones(userMilestones),
        },
        stagePayment: {
          ...state.stagePayment,
          milestones: corporateMilestones,
        },
        paymentPlan: paymentPlans.stagePayment,
        currentStage: registrationStages.initiatePayment,
      };
    }

    case types.CHOOSE_COURSE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case types.CHOOSE_COURSE_SUCCESS: {
      if (
        action.data.course === state.course &&
        action.data.courseType === state.courseType
      ) {
        return {
          ...state,
          isLoading: false,
          currentStage: registrationStages.courseSelected,
        };
      }
      const course = formatCourse(action.data.course);
      if (!course) {
        return {
          ...state,
          isLoading: false,
        };
      }
      const { courseType } = action.data;
      const { milestones } = course;
      milestones.sort((a, b) => a.level - b.level);
      const summary = getSummaryFromMilestones(milestones);
      const promotion =
        course.amount.promotions && course.amount.promotions.length > 0
          ? course.amount.promotions[0]
          : null;

      const milestonesForStage = [milestones[0]];
      const nextIndex = 1;

      for (let i = nextIndex; i < milestones.length; i += 1) {
        const courseMilestone = milestones[i];
        if (
          !courseMilestone ||
          courseMilestone.amount.totalAmountWithDiscount > 0
        ) {
          break;
        }
        milestonesForStage.push(courseMilestone);
      }
      return {
        ...state,
        isLoading: false,
        summary,
        course,
        courseType,
        promotion,
        stagePayment: {
          ...state.stagePayment,
          milestones: milestonesForStage,
          summary: getSummaryFromMilestones(milestonesForStage),
        },
        fullPayment: {
          summary,
        },
        paymentPlan: paymentPlans.fullPayment,
        currentStage: registrationStages.courseSelected,
        onlinePaymentInfo: undefined,
      };
    }
    case types.NEXT_SELECTED:
      return { ...state, blockIndex: action.data.index };
    case types.SET_COMPLETED:
      return { ...state, isCompleted: true };

    case types.CHANGE_PAYMENT_PLAN: {
      const plan = action.data;
      if (plan === state.paymentPlan) {
        return state;
      }
      if (plan === paymentPlans.fullPayment) {
        return {
          ...state,
          summary: state.fullPayment.summary,
          paymentPlan: plan,
          showCardInput: false,
        };
      }
      if (plan === paymentPlans.stagePayment) {
        return {
          ...state,
          summary: state.stagePayment.summary,
          paymentPlan: plan,
          showCardInput: false,
        };
      }
      return state;
    }
    case types.SELECT_PAYMENT_METHOD: {
      const { paymentMethod } = action.data;
      const showCardInput =
        paymentMethod === 'card' ||
        paymentMethod === 'link' ||
        paymentMethod === 'tabby';
      const showTabbyCardType = paymentMethod === 'tabby';

      return {
        ...state,
        paymentMethod,
        showCardInput,
        showTabbyCardType,
      };
    }
    case types.SELECT_MILESTONE: {
      if (state.paymentPlan !== paymentPlans.stagePayment) {
        return state;
      }
      const { milestone, selected } = action.data;
      let { milestones } = state.stagePayment;
      const { course } = state;

      let courseMilestones = (course || { milestones: [] }).milestones;
      courseMilestones = courseMilestones.sort((a, b) => a.level - b.level);
      if (selected) {
        milestones = courseMilestones.filter(
          (cm) => cm.level <= milestone.level
        );
      } else {
        milestones = courseMilestones.filter(
          (cm) => cm.level < milestone.level
        );
      }
      const nextIndex = milestones.length;

      for (let i = nextIndex; i < courseMilestones.length; i += 1) {
        const courseMilestone = courseMilestones[i];
        if (
          !courseMilestone ||
          courseMilestone.amount.totalAmountWithDiscount > 0
        ) {
          break;
        }
        milestones.push(courseMilestone);
      }
      milestones = milestones.sort((a, b) => a.level - b.level);

      const summary = getSummaryFromMilestones(milestones);
      return {
        ...state,
        stagePayment: {
          ...state.stagePayment,
          milestones,
          summary,
        },
        summary,
      };
    }

    case types.SELECT_CORPORATE_MILESTONE: {
      // if (state.paymentPlan !== paymentPlans.stagePayment) {
      //   return state;
      // }
      const { milestone, selected } = action.data;
      let { milestones } = state.userPayment;

      const { course } = state;

      let courseMilestones = (course || { milestones: [] }).userMilestones;
      courseMilestones = courseMilestones.sort((a, b) => a.level - b.level);
      if (selected) {
        milestones = courseMilestones.filter(
          (cm) => cm.level <= milestone.level
        );
      } else {
        milestones = courseMilestones.filter(
          (cm) => cm.level < milestone.level
        );
      }
      let newMilestones = [...state.corporatePayment.milestones, ...milestones];

      const nextIndex = milestones.length;

      // for (let i = nextIndex; i < courseMilestones.length; i += 1) {
      //   const courseMilestone = courseMilestones[i];
      //   if (
      //     !courseMilestone ||
      //     courseMilestone.amount.totalAmountWithDiscount > 0
      //   ) {
      //     break;
      //   }
      //   milestones.push(courseMilestone);
      // }
      newMilestones = newMilestones.sort((a, b) => a.level - b.level);

      const summary = getSummaryFromMilestones(milestones);
      return {
        ...state,
        userPayment: {
          ...state.userPayment,
          // milestones,
          summary,
        },
        stagePayment: {
          milestones: newMilestones,
          summary,
        },
        summary,
      };
    }

    // payment
    case types.CONTINUE_TO_PAYMENT:
      return {
        ...state,
        currentStage: registrationStages.completePayment,
        paymentMethod: '',
        showCardInput: null,
        showTabbyCardType: null,
        tabbyCardType: null,
        tabbyCharge: 0,
        tabbyTax: 0,
      };
    case types.PAYMENT_INITIATE_REQUEST:
      return { ...state, isLoading: true };
    case types.PAYMENT_INITIATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentStage: registrationStages.finished,
        isCompleted: true,
        paymentDetails: action.data,
      };
    case types.PAYMENT_INITIATE_ERROR:
      return { ...state, isLoading: false, isPaymentCompleted: false };
    case types.PAYMENT_COMPLETE_REQUEST:
      return { ...state, isLoading: true };
    case types.PAYMENT_COMPLETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentStage: registrationStages.finished,
        isPaymentCompleted: true,
        paymentHistoryId: action.data ? action.data.paymentHistoryId : null,
        paymentUserId:
          action.data && action.data.userCourseData
            ? action.data.userCourseData.user
            : null,
        paymentCourseId:
          action.data && action.data.userCourseData
            ? action.data.userCourseData._id
            : null,
        isLumpSumCourse:
          action.data && action.data.userCourseData
            ? action.data.userCourseData.courseType.isLumpSumCourse
            : false,
      };
    case types.PAYMENT_COMPLETE_ERROR:
      return { ...state, isLoading: false, isPaymentCompleted: false };

    case types.CLEAR_DATA:
      return {
        // ...state,
        isCompleted: false,
        isPaymentCompleted: false,
        record: null,
        isLoading: false,
        verify: null,
        hasCourse: false,
        course: null,
        courseType: null,
        promotion: null,
        stagePayment: null,
        fullPayment: null,
        paymentPlan: null,
        currentStage: '',
        blockIndex: 0,
        courses: null,
        hasPromotion: false,
        summary: null,
        totalAmount: 0,
        fullPaymentSelected: 0,
        totalForMilestones: 0,
        totalRtaForMilestones: 0,
        totalCompForMilestones: 0,
        paymentMethod: '',
        milestones: [],
        token: null,
        payingAmount: 0,
        student: null,
        paymentHistoryId: null,
        step: 1,
        totalDiscount: 0,
        hasReferral: false,
        referralList: [],
        referralCode: '',
        tokenBalanceAmount: 0,
        showCardInput: null,
        showTabbyCardType: null,
        tabbyCardType: null,
      };

    case types.FETCH_UPDATED_REQUEST:
      return { ...state };

    case types.FETCH_UPDATED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        record: {
          userRecord: action.data,
        },
      };
    case types.FETCH_UPDATED_ERROR:
      return { ...state, isLoading: false };

    // promoCode
    case types.PROMOCODE_INITIATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        showPromoCodeBox: true,
      };
    case types.PROMOCODE_INITIATE_SUCCESS:
      console.log('succe');
      return {
        ...state,
        isLoading: false,
        showPromoCodeBox: true,
        promotions: action.data,
      };
    case types.PROMOCODE_INITIATE_ERROR:
      console.log('Errrrr');
      return {
        ...state,
        isLoading: false,
        showPromoCodeBox: false,
      };

    // promoCode
    case types.APPLY_PROMOCODE_INITIATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        showPromoCodeBox: false,
      };
    case types.APPLY_PROMOCODE_CLOSE:
      return {
        ...state,
        isLoading: false,
        showPromoCodeBox: false,
      };
    case types.APPLY_PROMOCODE_INITIATE_SUCCESS: {
      const course = action.data;
      if (!course) {
        return {
          ...state,
          isLoading: false,
        };
      }
      const {
        courseType: {
          name: { en },
        },
      } = action.data;
      const { milestones } = course;
      milestones.sort((a, b) => a.level - b.level);
      const summary = getSummaryFromMilestones(milestones);
      const promotion =
        course.amount.promotions && course.amount.promotions.length > 0
          ? course.amount.promotions[0]
          : null;

      const milestonesForStage = [milestones[0]];
      const nextIndex = 1;

      for (let i = nextIndex; i < milestones.length; i += 1) {
        const courseMilestone = milestones[i];
        if (
          !courseMilestone ||
          courseMilestone.amount.totalAmountWithDiscount > 0
        ) {
          break;
        }
        milestonesForStage.push(courseMilestone);
      }
      return {
        ...state,
        isLoading: false,
        summary,
        course,
        courseType: en,
        promotion,
        stagePayment: {
          ...state.stagePayment,
          milestones: milestonesForStage,
          summary: getSummaryFromMilestones(milestonesForStage),
        },
        fullPayment: {
          summary,
        },
        paymentPlan: paymentPlans.fullPayment,
        currentStage: registrationStages.courseSelected,
      };
    }
    case types.APPLY_PROMOCODE_INITIATE_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case types.INVOICE_SELECTED:
      return { ...state, invoiceId: action.data.invoiceId, isLumpSum: false };

    case types.LUMPSUM_INVOICE_SELECTED:
      return {
        ...state,
        isLumpSum: true,
        invoiceId: action.data.courseId,
      };

    // EID
    case types.READ_EID_REQUEST:
      return { ...state, isLoading: true, record: null, eidData: null };
    case types.READ_EID_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        record: action.data.record,
        eidData: action.data.eidData,
      };
    }
    case types.READ_EID_ERROR:
      return { ...state, isLoading: false };

    case types.HAS_REFERRAL:
      return {
        ...state,
        isLoading: false,
        hasReferral: action.data.hasReferral,
      };

    // referral
    case types.REFERRAL_INITIATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.REFERRAL_INITIATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case types.REFEERAL_INITIATE_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    // REFERRAL APPLY
    case types.APPLY_REFERRAL_INITIATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.APPLY_REFERRAL_CLOSE:
      return {
        ...state,
        isLoading: false,
      };
    case types.APPLY_REFERRAL_INITIATE_SUCCESS:
      return {
        ...state,
        referralList: action.data.referralList,
        referralCode: action.data.referralCode,
        referralAmount: action.data.referralAmount,
        referralType: action.data.referralType,
        isLoading: false,
      };
    // case types.APPLY_REFERRAL_INITIATE_SUCCESS: {
    //   const course = action.data;
    //   if (!course) {
    //     return {
    //       ...state,
    //       isLoading: false,
    //     };
    //   }
    //   const {
    //     courseType: {
    //       name: { en },
    //     },
    //   } = action.data;
    //   const { milestones, amount } = course;
    //   milestones.sort((a, b) => a.level - b.level);
    //   const summary = getSummaryFromMilestones(milestones);
    //   const promotion =
    //     course.amount.promotions && course.amount.promotions.length > 0
    //       ? course.amount.promotions[0]
    //       : null;
    //   const referrals =
    //     course.amount.referrals && course.amount.referrals.length > 0
    //       ? course.amount.referrals[0]
    //       : null;

    //   const milestonesForStage = [milestones[0]];
    //   const nextIndex = 1;

    //   for (let i = nextIndex; i < milestones.length; i += 1) {
    //     const courseMilestone = milestones[i];
    //     if (
    //       !courseMilestone ||
    //       courseMilestone.amount.totalAmountWithDiscount > 0
    //     ) {
    //       break;
    //     }
    //     milestonesForStage.push(courseMilestone);
    //   }
    //   return {
    //     ...state,
    //     isLoading: false,
    //     summary,
    //     course,
    //     courseType: en,
    //     promotion,
    //     stagePayment: {
    //       ...state.stagePayment,
    //       milestones: milestonesForStage,
    //       summary: getSummaryFromMilestones(milestonesForStage),
    //     },
    //     fullPayment: {
    //       summary,
    //     },
    //     paymentPlan: paymentPlans.fullPayment,
    //     currentStage: registrationStages.courseSelected,
    //     referrals,
    //   };
    // }
    case types.APPLY_REFERRAL_INITIATE_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case types.UPDATE_TABBY_CHARGE: {
      const { tabbyCharge } = action.data;
      const tabbyTax = (tabbyCharge * 5) / 100;
      return {
        ...state,
        tabbyCharge,
        tabbyTax,
      };
    }
    case types.FETCH_ENQUIRY_DETAILS_REQUEST:
      return { ...state, isLoading: true };

    case types.FETCH_ENQUIRY_DETAILS_SUCCESS:
      return {
        ...state,
        verify: action.data,
        isLoading: false,
        isCompleted: false,
      };
    case types.FETCH_ENQUIRY_DETAILS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.data?.errorMessage,
      };
    default:
      return state;
  }
};
const formatCourse = (course) => {
  if (!course || !course.milestones) {
    return course;
  }
  return {
    ...course,
    milestones: course.milestones
      .map((ml) => {
        return {
          ...ml,
          stages: ml.instanceIds
            .map((stId) => course.stages.find((st) => st.stage._id === stId))
            .filter((sta) => sta !== undefined),
        };
      })
      .map((ml) => {
        return {
          ...ml,
          // amount: ml.stages.reduce(
          //   summaryMapReduce.reduce,
          //   summaryMapReduce.initial
          // ),
        };
      }),
  };
};

const getSummaryFromMilestones = (milestones) => {
  return milestones.reduce(summaryMapReduce.reduce, summaryMapReduce.initial);
};

// action creators & async actions
export const actions = {
  getCourses: (request) => async (dispatch) => {
    dispatch({
      type: types.GET_COURSES_REQUEST,
      currentPage: request.pagination.page,
    });
    try {
      const response = await crudApi.getFullList(request);
      dispatch({
        type: types.GET_COURSES_SUCCESS,
        data: response.data,
        count: Number(response.count),
        currentPage: request.pagination.page,
      });
    } catch (error) {
      // console.log(error);
      dispatch({ type: types.GET_COURSES_ERROR });
      if (error.response && error.response.data) {
        toastr.error('Error', error.response.data.message);
      }
      handleCommonApiErrors(error);
    }
  },

  get: (request) => async (dispatch) => {
    dispatch({ type: types.CRUD_READ_REQUEST });
    try {
      const response = await crudApi.get(request);
      // console.log('response(success): ', response);
      dispatch({
        type: types.CRUD_READ_SUCCESS,
        data: response.data,
      });
    } catch (error) {
      // console.log(error);
      dispatch({ type: types.CRUD_READ_ERROR });
      if (error.response && error.response.data) {
        toastr.error('Error', error.response.data.message);
      }
      handleCommonApiErrors(error);
    }
  },

  getVerifiedToken: (request) => async (dispatch) => {
    dispatch({ type: types.CRUD_SIGNUP_REQUEST });
    try {
      const response = await crudApi.get(request);
      // console.log('response(success): ', response);
      dispatch({
        type: types.CRUD_SIGNUP_SUCCESS,
        data: response.data,
      });
    } catch (error) {
      // console.log(error);
      dispatch({ type: types.CRUD_SIGNUP_ERROR });
      if (error.response && error.response.data) {
        toastr.error('Error', error.response.data.message);
      }
      throw error;
    }
  },

  create: (request, data) => async (dispatch) => {
    dispatch({ type: types.CRUD_READ_REQUEST });
    try {
      const response = await crudApi.create(request, data);
      // console.log('response(success): ', response);
      dispatch({
        type: types.CRUD_READ_SUCCESS,
        data: response.data,
      });
      toastr.success('Success', 'Creation Successfull!');
    } catch (error) {
      dispatch({ type: types.CRUD_READ_ERROR });
      serializeAndShowFormErrors(error);
      handleCommonApiErrors(error);
    }
  },

  update: (request, data) => async (dispatch) => {
    dispatch({ type: types.CRUD_UPDATE_REQUEST });
    try {
      const response = await crudApi.update(request, data);
      // console.log('response(success): ', response);
      dispatch({
        type: types.CRUD_UPDATE_SUCCESS,
        data: response.data,
      });
      toastr.success('Success', 'Updation Successfull!');
    } catch (error) {
      dispatch({ type: types.CRUD_UPDATE_ERROR });
      serializeAndShowFormErrors(error);
      handleCommonApiErrors(error);
    }
  },

  updateByToken: (request, data) => async (dispatch) => {
    dispatch({ type: types.CRUD_UPDATE_REQUEST });
    try {
      const response = await crudApi.updateByToken(request, data);
      // console.log('response(success): ', response);
      dispatch({
        type: types.CRUD_UPDATE_SUCCESS,
        data: response.data,
      });
      // toastr.success('Success', 'Updation Successfull!');
    } catch (error) {
      dispatch({ type: types.CRUD_UPDATE_ERROR });
      serializeAndShowFormErrors(error);
      handleCommonApiErrors(error);
    }
  },

  remove: (request, record) => async (dispatch) => {
    dispatch({ type: types.CRUD_DELETE_REQUEST });
    try {
      await crudApi.remove(request);
      // console.log('response(success): ', response);
      dispatch({
        type: types.CRUD_DELETE_SUCCESS,
        record,
      });
      toastr.success('Success', 'Delete successful!');
    } catch (error) {
      serializeAndShowFormErrors(error);
      handleCommonApiErrors(error);
    }
  },

  signUp: (request, data) => async (dispatch) => {
    dispatch({ type: types.CRUD_SIGNUP_REQUEST });
    try {
      const response = await crudApi.create(request, data);
      // console.log('response(success): ', response);
      localStorage.setItem('otpTimer', `180`);
      localStorage.setItem('otpStartTime', `${Date.now()}`);
      dispatch({
        type: types.CRUD_SIGNUP_SUCCESS,
        data: response.data,
      });
      // toastr.success('Success', 'Creation Successfull!');
    } catch (error) {
      dispatch({ type: types.CRUD_SIGNUP_ERROR });
      serializeAndShowFormErrors(error);
      handleCommonApiErrors(error);
      throw error;
    }
  },
  signUpUpdate: (request, data) => async (dispatch) => {
    dispatch({ type: types.CRUD_SIGNUP_REQUEST });
    try {
      const response = await crudApi.createWithId(request, data);
      // console.log('response(success): ', response);
      dispatch({
        type: types.CRUD_SIGNUP_UPDATE_SUCCESS,
        data: response.data,
        token: request.token,
      });
      // toastr.success('Success', 'Creation Successfull!');
    } catch (error) {
      dispatch({ type: types.CRUD_SIGNUP_ERROR });
      serializeAndShowFormErrors(error);
      handleCommonApiErrors(error);
    }
  },
  selectCourse: (course, courseType, token, promoCode) => async (dispatch) => {
    dispatch({ type: types.CHOOSE_COURSE_REQUEST });
    try {
      const data = {
        courseType,
        courseCode: course.course.code,
        promoCode,
      };
      const response = await crudApi.chooseCourse(data, token);
      const selectedCourse = response.data.userCourseData;
      const tokenBalanceAmount = response?.data?.tokenBalanceAmount;
      const referralList = [];
      const referralCode = '';
      const referralAmount = 0;
      const tabbyConfigurationResponse = await crudApi.getTabbyConfiguration();
      dispatch({
        type: types.COURSE_SELECTED,
        data: {
          course: selectedCourse,
          courseType,
          tokenBalanceAmount,
          referralList,
          referralCode,
          referralAmount,
          virtualTabbyPercentage:
            tabbyConfigurationResponse?.virtualTabbyPercentage ?? 4.71,
          otherTabbyPercentage:
            tabbyConfigurationResponse?.otherTabbyPercentage ?? 2.71,
          tabbyoption: tabbyConfigurationResponse?.tabbyoption,
        },
      });
    } catch (error) {
      dispatch({ type: types.CHOOSE_COURSE_ERROR });
    }
  },
  selectCorporateCourse: (course, courseType, corporateId, token) => async (
    dispatch
  ) => {
    dispatch({ type: types.CHOOSE_COURSE_REQUEST });
    try {
      const params = {
        courseType,
        courseCode: course.course.code,
        corporateId,
      };
      const response = await crudApi.chooseCorporateCourse(params, token);
      const { data } = response;

      dispatch({
        type: types.CORPORATE_COURSE_SELECTED,
        data: {
          course,
          courseType,
          corporateId,
          corporateMilestones:
            data && data.userCourseData
              ? data.userCourseData.corporateMilestones
              : [],
          userMilestones:
            data && data.userCourseData
              ? data.userCourseData.userMilestones
              : [],
        },
      });
    } catch (error) {
      dispatch({ type: types.CHOOSE_COURSE_ERROR });
    }
  },

  selectVIPCourse: (course, courseType, token) => async (dispatch) => {
    dispatch({ type: types.CHOOSE_COURSE_REQUEST });
    try {
      const data = {
        courseType,
        courseCode: course.course.code,
      };
      const response = await crudApi.chooseVIPCourse(data, token);
      const selectedCourse = response.data.userCourseData;
      dispatch({
        type: types.COURSE_SELECTED,
        data: { course: selectedCourse, courseType },
      });
    } catch (error) {
      dispatch({ type: types.CHOOSE_COURSE_ERROR });
    }
  },
  previewCourse: (course, courseType) => async (dispatch) => {
    dispatch({ type: types.CHOOSE_COURSE_REQUEST });
    try {
      dispatch({
        type: types.COURSE_SELECTED,
        data: { course, courseType },
      });
    } catch (error) {
      dispatch({ type: types.CHOOSE_COURSE_ERROR });
    }
  },
  nextBlock: (index) => async (dispatch) => {
    dispatch({
      type: types.NEXT_SELECTED,
      data: { index },
    });
  },
  setCompleted: () => async (dispatch) => {
    dispatch({
      type: types.SET_COMPLETED,
    });
  },
  changePaymentPlan: (plan) => (dispatch) => {
    dispatch({
      type: types.CHANGE_PAYMENT_PLAN,
      data: plan,
    });
  },
  continueToPayment: () => (dispatch) => {
    dispatch({
      type: types.CONTINUE_TO_PAYMENT,
    });
  },
  selectPaymentMethod: (paymentMethod) => (dispatch) => {
    dispatch({
      type: types.SELECT_PAYMENT_METHOD,
      data: { paymentMethod },
    });
  },
  selectMilestone: (milestone, selected) => (dispatch) => {
    dispatch({
      type: types.SELECT_MILESTONE,
      data: { milestone, selected },
    });
  },
  selectCorporateMilestone: (milestone, selected) => (dispatch) => {
    dispatch({
      type: types.SELECT_CORPORATE_MILESTONE,
      data: { milestone, selected },
    });
  },
  clearData: () => (dispatch) => {
    dispatch({
      type: types.CLEAR_DATA,
    });
  },
  fetchUpdatedRecord: (request) => async (dispatch) => {
    dispatch({ type: types.FETCH_UPDATED_REQUEST });
    try {
      const response = await UserApi.getStudentProfile(request);
      const { data } = response;
      dispatch({ type: types.FETCH_UPDATED_SUCCESS, data });
    } catch (error) {
      dispatch({ type: types.FETCH_UPDATED_ERROR });
      handleCommonApiErrors(error);
    }
  },
  initiatePayment: (
    milestones,
    totalAmount,
    courseType,
    promotion,
    token,
    paymentMethod,
    createdBy,
    bank,
    card,
    last4,
    authCode,
    paymentDate,
    tabbyCardType
  ) => async (dispatch) => {
    dispatch({ type: types.PAYMENT_INITIATE_REQUEST });
    try {
      const cardDetails =
        paymentMethod === 'card' ||
        paymentMethod === 'link' ||
        paymentMethod === 'tabby'
          ? {
              bank,
              card,
              last4,
              authCode,
            }
          : undefined;

      if (
        paymentMethod === 'card' ||
        paymentMethod === 'link' ||
        paymentMethod === 'tabby'
      ) {
        if (paymentMethod === 'tabby') {
          cardDetails.tabbyCardType = tabbyCardType;
        }
        if (
          paymentMethod === 'card' ||
          paymentMethod === 'link' ||
          paymentMethod === 'tabby'
        ) {
          if (paymentMethod === 'tabby' && !tabbyCardType) {
            dispatch({ type: types.PAYMENT_INITIATE_ERROR });
            toastr.error('Error', 'Please select tabby card type');
            return;
          }
        }

        if (!bank || !card || !last4 || last4.length !== 4 || !authCode) {
          dispatch({ type: types.PAYMENT_INITIATE_ERROR });
          if (last4.length !== 4 && last4.length !== 0) {
            toastr.error('Error', 'Card number must be 4 digit');
          } else {
            toastr.error('Error', 'Please select card details');
          }
          return;
        }
      }
      const paymentType = paymentMethod || 'cash';
      const data = {
        milestones,
        amount: totalAmount || 0,
        courseType,
        promotionId: promotion != null ? promotion._id : null,
        isCsrPayment: true,
        paymentMethod: paymentType,
        createdBy,
        cardDetails,
        platform: 'csr',
        paymentDate,
      };

      if (paymentMethod === 'tabby') {
        const cardData = {
          cardDetails,
        };
        await crudApi.updateTabbyDetails(cardData, token);
      } else {
        await crudApi.updateNonTabbyDetails({}, token);
      }

      // const response = await crudApi.initiatePayment(token, data);
      // console.log('response: ', response);
      dispatch({ type: types.PAYMENT_INITIATE_SUCCESS, data });
      // toastr.success('Success', '');
    } catch (error) {
      dispatch({ type: types.PAYMENT_INITIATE_ERROR });
      /* toast the error messages */
      // console.log('error Logging in...', error);
      // console.log('error Logging in(response)...', error.response);
      // toastr.error('Error', 'Error Verifying OTP');
      // toastr.error('Error', error.response.data.message);
      handleCommonApiErrors(error);
    }
  },
  completePayment: (
    milestones,
    totalAmount,
    courseType,
    promotion,
    token,
    paymentMethod,
    createdBy,
    bank,
    card,
    last4,
    authCode,
    paymentDate,
    referralAmount,
    referralList,
    referralCode,
    tabbyCardType,
    lumpsumNetAmount
  ) => async (dispatch) => {
    dispatch({ type: types.PAYMENT_COMPLETE_REQUEST });
    try {
      const cardDetails =
        paymentMethod === 'card' ||
        paymentMethod === 'link' ||
        paymentMethod === 'tabby'
          ? {
              bank,
              card,
              last4,
              authCode,
            }
          : undefined;

      if (paymentMethod === 'tabby') {
        cardDetails.tabbyCardType = tabbyCardType;
      }
      if (paymentMethod === 'tabby' && !tabbyCardType) {
        dispatch({ type: types.PAYMENT_INITIATE_ERROR });
        toastr.error('Error', 'Please select tabby card type');
        return;
      }

      if (paymentMethod === 'card' || paymentMethod === 'link') {
        if (!bank || !card || !last4 || last4.length !== 4 || !authCode) {
          dispatch({ type: types.PAYMENT_INITIATE_ERROR });
          if (last4.length !== 4 && last4.length !== 0) {
            toastr.error('Error', 'Card number must be 4 digit');
          } else {
            toastr.error('Error', 'Please select card details');
          }
          return;
        }
      }
      const paymentType = paymentMethod || 'cash';
      const data = {
        milestones,
        amount: totalAmount,
        courseType,
        promotionId: promotion != null ? promotion._id : null,
        isCsrPayment: true,
        paymentMethod: paymentType,
        createdBy,
        cardDetails,
        platform: 'csr',
        paymentDate,
        referralAmount,
        referralList,
        referralCode,
        lumpsumAmount: lumpsumNetAmount,
      };

      const response = await crudApi.initiatePayment(token, data);
      // console.log('response: ', response);
      dispatch({ type: types.PAYMENT_COMPLETE_SUCCESS, data: response.data });
      // toastr.success('Success', '');
    } catch (error) {
      dispatch({ type: types.PAYMENT_COMPLETE_ERROR });
      toastr.error('Error', error.response.data.message);
      /* toast the error messages */
      // console.log('error Logging in...', error);
      // console.log('error Logging in(response)...', error.response);
      // toastr.error('Error', 'Error Verifying OTP');
      // toastr.error('Error', error.response.data.message);
      // throw error;
      handleCommonApiErrors(error);
    }
  },
  showInvoiceBlock: (invoiceId) => async (dispatch) => {
    dispatch({
      type: types.INVOICE_SELECTED,
      data: { invoiceId },
    });
  },
  showLumpSumInvoiceBlock: (courseId) => async (dispatch) => {
    console.log('showLumpSumInvoiceBlock', courseId);
    dispatch({
      type: types.LUMPSUM_INVOICE_SELECTED,
      data: { courseId },
    });
  },
  clearPromoCode: (token, course) => async (dispatch) => {
    dispatch({ type: types.PROMOCODE_INITIATE_REQUEST });
    try {
      const data = {
        token,
        courseId: course && course.course ? course.course._id : '',
      };
      const response = await crudApi.getPromotions(data);
      // const response = [];
      dispatch({ type: types.PROMOCODE_INITIATE_SUCCESS, data: response.data });
      // toastr.success('Success', 'You are now Logged In!');
    } catch (error) {
      dispatch({ type: types.PROMOCODE_INITIATE_ERROR });
      /* toast the error messages */
      // console.log('error Logging in...', error);
      // console.log('error Logging in(response)...', error.response);
      // toastr.error('Error', 'Error Verifying OTP');
      // toastr.error('Error', error.response.data.message);
      handleCommonApiErrors(error);
    }
  },
  applyPromoCode: (promoCode, token) => async (dispatch) => {
    dispatch({ type: types.APPLY_PROMOCODE_INITIATE_REQUEST });
    try {
      if (!promoCode) {
        dispatch({ type: types.APPLY_PROMOCODE_CLOSE });
      } else {
        const data = { promoCode };
        const response = await crudApi.applyPromotion(data, token);
        dispatch({
          type: types.APPLY_PROMOCODE_INITIATE_SUCCESS,
          data: response.data.userCourseData,
        });
      }
      // toastr.success('Success', 'You are now Logged In!');
    } catch (error) {
      dispatch({ type: types.APPLY_PROMOCODE_INITIATE_ERROR });
      /* toast the error messages */
      // console.log('error Logging in...', error);
      // console.log('error Logging in(response)...', error.response);
      // toastr.error('Error', 'Error Verifying OTP');
      toastr.error('Error', error.response.data.message);
      handleCommonApiErrors(error);
    }
  },
  readEid: (record, eidData) => async (dispatch) => {
    dispatch({ type: types.READ_EID_REQUEST });
    try {
      dispatch({
        type: types.READ_EID_SUCCESS,
        data: { record, eidData },
      });
    } catch (error) {
      dispatch({ type: types.READ_EID_ERROR });
      handleCommonApiErrors(error);
    }
  },
  showReferralBox: (hasReferral, token) => async (dispatch) => {
    dispatch({
      type: types.HAS_REFERRAL,
      data: { hasReferral },
    });

    if (!hasReferral) {
      dispatch({ type: types.APPLY_REFERRAL_INITIATE_REQUEST });
      try {
        const referral = '';
        const data = { referral };
        const response = await crudApi.cancellReferral(data, token);
        const referralList = [];
        const referralCode = referral;
        const referralAmount = 0;
        dispatch({
          type: types.APPLY_REFERRAL_INITIATE_SUCCESS,
          data: { referralList, referralCode, referralAmount },
        });
        dispatch({ type: types.APPLY_REFERRAL_CLOSE });
        toastr.success('Success', 'Referral Removed Successfully !');
      } catch (error) {
        dispatch({ type: types.APPLY_REFERRAL_INITIATE_ERROR });

        toastr.error('Error', error.response.data.message);
        handleCommonApiErrors(error);
      }
    }
  },
  applyReferral: (referral, referralType, token) => async (dispatch) => {
    dispatch({ type: types.APPLY_REFERRAL_INITIATE_REQUEST });
    try {
      if (!referral) {
        dispatch({ type: types.APPLY_REFERRAL_CLOSE });
      } else {
        let data = null;

        if (referral && referralType) {
          data = { referral, referralType };
        } else {
          data = { referral };
        }

        const response = await crudApi.applyReferral(data, token);
        const referralList = [response.data.referralList[0]];
        const referralAmount =
          response.data.referralList?.length > 0
            ? response.data.referralList[0]?.refereeAmount
            : 0;
        const referralCode = referral;
        dispatch({
          type: types.APPLY_REFERRAL_INITIATE_SUCCESS,
          data: { referralList, referralCode, referralAmount, referralType },
        });
      }
      toastr.success('Success', 'Referral Applied Successfully !');
    } catch (error) {
      dispatch({ type: types.APPLY_REFERRAL_INITIATE_ERROR });
      toastr.error('Error', error.response.data.message);
      handleCommonApiErrors(error);
    }
  },
  removeReferral: (referral, token) => async (dispatch) => {
    dispatch({ type: types.APPLY_REFERRAL_INITIATE_REQUEST });
    try {
      if (!referral) {
        dispatch({ type: types.APPLY_REFERRAL_CLOSE });
      } else {
        const data = { referral };
        const response = await crudApi.removeReferral(data, token);
        const referralList = [];
        const referralCode = '';
        const referralAmount = 0;

        dispatch({
          type: types.APPLY_REFERRAL_INITIATE_SUCCESS,
          data: { referralList, referralCode, referralAmount },
        });
      }
      toastr.success('Success', 'Referral Removed Successfully !');
    } catch (error) {
      dispatch({ type: types.APPLY_REFERRAL_INITIATE_ERROR });
      toastr.error('Error', error.response.data.message);
      handleCommonApiErrors(error);
    }
  },

  quickSignUp: (request, data) => async (dispatch) => {
    dispatch({ type: types.CRUD_SIGNUP_REQUEST });
    try {
      const response = await UserApi.preregisterStudent(request, data);
      localStorage.setItem('otpTimer', `180`);
      localStorage.setItem('otpStartTime', `${Date.now()}`);
      dispatch({
        type: types.CRUD_SIGNUP_SUCCESS,
        data: response.data,
      });
      // toastr.success('Success', 'Creation Successfull!');
    } catch (error) {
      dispatch({ type: types.CRUD_SIGNUP_ERROR });
      serializeAndShowFormErrors(error);
      handleCommonApiErrors(error);
      throw error;
    }
  },

  completeQuickSignUpPayment: (
    token,
    paymentMethod,
    createdBy,
    createdUser,
    bank,
    card,
    last4,
    authCode,
    paymentDate
  ) => async (dispatch) => {
    dispatch({ type: types.PAYMENT_COMPLETE_REQUEST });
    try {
      const cardDetails =
        paymentMethod === 'card'
          ? {
              bank,
              card,
              last4,
              authCode,
            }
          : undefined;

      if (paymentMethod === 'card') {
        if (!bank || !card || !last4 || !authCode) {
          dispatch({ type: types.PAYMENT_INITIATE_ERROR });
          toastr.error('Error', 'Please select card details');
          return;
        }
      }
      const paymentType = paymentMethod || 'cash';
      const data = {
        isCsrPayment: true,
        paymentMethod: paymentType,
        createdBy: {
          _id: createdBy,
          name: createdUser,
        },
        cardDetails,
        platform: 'csr',
        paymentDate,
      };
      const response = await crudApi.initiateQuickSignUpPayment(token, data);
      // console.log('response: ', response);
      dispatch({ type: types.PAYMENT_COMPLETE_SUCCESS, data: response.data });
      // toastr.success('Success', '');
    } catch (error) {
      dispatch({ type: types.PAYMENT_COMPLETE_ERROR });
      toastr.error('Error', error.response.data.message);
      /* toast the error messages */
      // console.log('error Logging in...', error);
      // console.log('error Logging in(response)...', error.response);
      handleCommonApiErrors(error);
    }
  },

  updateTabbyCharge: (tabbyCharge) => (dispatch) => {
    dispatch({
      type: types.UPDATE_TABBY_CHARGE,
      data: { tabbyCharge },
    });
  },
  fetchEnquiryDetails: (request, onSuccess) => async (dispatch) => {
    dispatch({ type: types.CLEAR_DATA });
    dispatch({ type: types.FETCH_ENQUIRY_DETAILS_REQUEST });
    try {
      const response = await crudApi.get(request);
      const { data } = response;
      await dispatch({ type: types.FETCH_ENQUIRY_DETAILS_SUCCESS, data });
      if (onSuccess) {
        onSuccess(data);
      }
    } catch (error) {
      let errorMsg = 'Something went wrong, please try again.';
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMsg = error.response.data.message;
      }
      dispatch({
        type: types.FETCH_ENQUIRY_DETAILS_ERROR,
        data: {
          errorMessage: errorMsg,
        },
      });
      handleCommonApiErrors(error);
    }
  },
};

export function serializeAndShowFormErrors(error) {
  if (error.response && error.response.data && error.response.data.errors) {
    const { errors } = error.response.data;
    Object.keys(errors).forEach((e) => {
      // console.log('error: ', e, errors[e].message);
      if (errors[e].errorMessage) {
        toastr.error(errors[e].errorMessage);
      } else {
        toastr.error(errors[e].message);
      }
    });
    return;
  }
  if (error.response && error.response.data && error.response.data.message) {
    toastr.error(error.response.data.message);

    return;
  }
  toastr.error('Error', 'Invalid Data in Form!');
}
