import { stringify } from 'query-string';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { toastr } from 'react-redux-toastr';
import API_DOMAIN from './apiDomain';
import { store } from '../store';
import { getUtcMidnight } from '../containers/helpers';

const pageSize = 20;

export async function getList(request, { getcount } = {}) {
  // ?_sort=name&_order=ASC&_filters={"name": "kumar"}
  const { page = 1, perPage = pageSize } = request.pagination;
  const { field, order } = request.sort;

  const query = {
    ...request.queryParams,
    _filters: JSON.stringify(request.filter),
    _sort: field,
    _order: order,
    _start: (page - 1) * perPage,
    _end: page * perPage,
    _getcount: getcount,
  };

  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${request.resource}?${stringify(query)}`;

  const response = await axios.get(url, options);
  return {
    data: response.data,
    count: response.headers['x-total-count'],
    pendingCount: response.headers['x-pending-count'],
    totalAmount: response.headers['x-total-amount'],
    totalCardAmount: response.headers['x-total-card'],
    totalCashAmount: response.headers['x-total-cash'],
    totalOnlineAmount: response.headers['x-total-online'],
    totalRefund: response.headers['x-total-refund'],
    totalAmountReceived: response.headers['x-total-amount-received'],
    totalAmountRemaining: response.headers['x-total-amount-remaining'],
    totalBalance: response.headers['x-total-amount-balance'],
    totalLinkAmount: response.headers['x-total-link'],
    totalTabbyAmount: response.headers['x-total-tabby'],
  };
}

export async function getListWithPost(request, { getcount } = {}) {
  const { page = 1, perPage = pageSize } = request.pagination;
  const { field, order } = request.sort;

  const query = {
    ...request.queryParams,
    _filters: JSON.stringify(request.filter),
    _sort: field,
    _order: order,
    _start: (page - 1) * perPage,
    _end: page * perPage,
    _getcount: getcount,
  };

  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${request.resource}`;

  const response = await axios.post(url, query, options);

  return {
    data: response.data,
    count: response.headers['x-total-count'],
  };
}

export async function getFullList(request) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const query = {
    _filters: JSON.stringify(request.filter),
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = request.filter
    ? `${API_DOMAIN}/${request.resource}?${stringify(query)}`
    : `${API_DOMAIN}/${request.resource}`;
  const response = await axios.get(url, options);
  // console.log('response: ', response);
  return { data: response.data, count: response.headers['x-total-count'] };
}

export async function getFullListCDD(request) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${request.resource}`;
  const response = await axios.get(url, options);
  // console.log('response: ', response);
  return { data: response.data, count: response.headers['x-total-count'] };
}

export async function create(request, data) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${request.resource}`;
  const response = await axios.post(url, data, options);
  return response;
}

export async function assignTicketToAssignee(request, data) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${request.resource}`;
  const response = await axios.put(url, data, options);
  return response;
}

export async function get(request) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${request.resource}/${request.resourceId}`;
  // console.log('url: ', request);
  const response = await axios.get(url, options);
  return response;
}

export async function getSignedDocumentUrl(request) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${request.resource}`;
  // console.log('url: ', request);
  const response = await axios.get(url, options);
  return response;
}

export async function update(request, data) {
  const payload = data;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  if (payload.__v) {
    delete payload.__v;
    delete payload.updatedAt;
  }
  const url = `${API_DOMAIN}/${request.resource}/${request.resourceId}`;
  const response = await axios.put(url, data, options);
  return response;
}

export async function updateByToken(request, data) {
  const payload = data;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  if (payload.__v) {
    delete payload.__v;
    delete payload.updatedAt;
  }
  const url = `${API_DOMAIN}/${request.resource}`;
  const response = await axios.put(url, data, options);
  return response;
}

export async function clone(request, data) {
  const payload = data;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (payload.__v) {
    delete payload.__v;
    delete payload.updatedAt;
  }
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${request.resource}/clone/${request.resourceId}`;
  const response = await axios.put(url, data, options);
  return response;
}

export async function remove(request) {
  const url = `${API_DOMAIN}/${request.resource}/${request.resourceId}`;
  // console.log('url: ', request);
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const response = await axios.delete(url, options);
  return response;
}

export async function deleteWithoutId(request, data) {
  const url = `${API_DOMAIN}/${request.resource}`;
  // console.log('url: ', request);
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const response = await axios.delete(url, options);
  return response;
}

export async function removeByWithoutId(request) {
  const url = `${API_DOMAIN}/${request.resource}`;
  // console.log('url: ', request);
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const response = await axios.put(url, {}, options);
  return response;
}

export async function removeByWithId(request) {
  const url = `${API_DOMAIN}/${request.resource}`;
  // console.log('url: ', request);
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const response = await axios.delete(url, {}, options);
  return response;
}

export async function push(request) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${request.resource}/push/${request.resourceId}`;
  const response = await axios.put(url, request.data, options);
  return response;
}

export async function saveCoupon(request, data) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${request.resource}/${request.resourceId}/save-coupon`;
  const response = await axios.put(url, data, options);
  return response;
}

export async function initiatePayment(token, data, params = null) {
  let url = `${API_DOMAIN}/user/v2/student/course/payment`;
  if (params && params.id) {
    url += `/${params.id}`;
  }
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }
  const response = await axios.post(url, data, options);
  return response;
}

export async function uploadImage(request, data) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${request.resource}/${request.resourceId}`;
  const response = await axios.post(url, data, options);
  return response;
}

export async function deleteImage(request, document) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  options.data = {
    document,
  };
  const url = `${API_DOMAIN}/${request.resource}/${request.resourceId}`;
  const response = await axios.delete(url, options);
  return response;
}

export async function removeByQuery(request) {
  const options = {
    headers: {},
  };
  const url = `${API_DOMAIN}/${request.resource}`;
  // console.log('url: ', request);
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  const response = await axios.delete(url, options);
  return response;
}

export async function chooseCourse(data, token) {
  const url = `${API_DOMAIN}/user/v2/student/course`;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }
  return axios.post(url, data, options);
}

export async function chooseVIPCourse(data, token) {
  const url = `${API_DOMAIN}/user/v2/student/vip-course`;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }
  return axios.post(url, data, options);
}

export async function chooseCorporateCourse(data, token) {
  const url = `${API_DOMAIN}/user/v1/student/corporate-course`;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }
  return axios.post(url, data, options);
}

export async function chooseCustomCourse(data, token) {
  const url = `${API_DOMAIN}/user/v1/student/custom-course`;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }
  return axios.post(url, data, options);
}

export async function chooseCustomLumpSumCourse(data, token) {
  const url = `${API_DOMAIN}/user/v1/student/custom-lumpsum-course`;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }
  return axios.post(url, data, options);
}

export async function getPromotions(data) {
  const query = {
    courseId: data.courseId,
  };
  if (data.userId) {
    query.userId = data.userId;
  }
  const url = `${API_DOMAIN}/user/v1/promotions?${stringify(query)}`;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (data.token) {
    options.headers.Authorization = `Bearer ${data.token}`;
  }
  const response = await axios.get(url, options);
  return response;
}

export async function applyPromotion(data, token) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }
  const url = `${API_DOMAIN}/user/v1/apply-promotion`;
  return axios.post(url, data, options);
}

export async function downloadInvoicePdf(request, id) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
    responseType: 'arraybuffer',
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/payment-history/${id}/pdf`;
  const response = await axios.get(url, options);
  return response;
}

export async function downloadPreRegistrationInvoicePdf(request, id) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
    responseType: 'arraybuffer',
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/payment-history/pre-registration/${id}/pdf`;
  const response = await axios.get(url, options);
  return response;
}

export async function sendPreRegistrationInvoiceMail(request, id) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
    responseType: 'arraybuffer',
  };

  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/payment-history/pre-registration/${id}/send-mail`;
  const response = await axios.post(url, {}, options);
  return response;
}

export async function downloadCorporateInvoice(request, id, corporateId) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
    responseType: 'arraybuffer',
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/corporate-invoice/pdf?corporateId=${corporateId}&invoiceNumber=${id}`;
  const response = await axios.get(url, options);
  return response;
}

export async function downloadLumpsumCorporateInvoice(
  request,
  id,
  corporateId
) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
    responseType: 'arraybuffer',
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/corporate-lumpsum-invoice/pdf?corporateId=${corporateId}&invoiceNumber=${id}`;
  const response = await axios.get(url, options);
  return response;
}

export async function downloadCTSSanityLog(request, date, trafficFileNo) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  let url = `${API_DOMAIN}/integrator/v1/integrator-sanitylog?date=${date}`;
  if (trafficFileNo) {
    url += `&id=${trafficFileNo}`;
  }
  const response = await axios.get(url, options);
  return response;
}
export async function downloadCTSStudentLog(
  request,
  startDate,
  endDate,
  trafficFileNo
) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/integrator/v1/CtsScheduleClass-Failed?startDate=${startDate}&endDate=${endDate}&id=${trafficFileNo}`;
  const response = await axios.get(url, options);
  return response;
}
export async function downloadCTSLicenseLog(
  request,
  startDate,
  endDate,
  trafficFileNo
) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/integrator/v1/CtsLicenseData-Failed?startDate=${startDate}&endDate=${endDate}&id=${trafficFileNo}`;
  const response = await axios.get(url, options);
  return response;
}

export async function downloadLumpSumInvoice(
  request,
  id,
  userId,
  invoiceNumber
) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
    responseType: 'arraybuffer',
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/lumpsum-payment/pdf?courseId=${id}&userId=${userId}${
    invoiceNumber ? `&invoiceNumber=${encodeURIComponent(invoiceNumber)}` : ''
  }`;
  const response = await axios.get(url, options);
  return response;
}

export async function sendLumpSumInvoice(request, id, userId, invoiceNumber) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
    responseType: 'arraybuffer',
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/lumpsum-payment/send-mail/?courseId=${id}&userId=${userId}${
    invoiceNumber ? `invoiceNumber=${encodeURIComponent(invoiceNumber)}` : ''
  }`;
  const response = await axios.post(url, {}, options);
  return response;
}

export async function sendInvoicePdf(request, id) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
    responseType: 'arraybuffer',
  };

  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/payment-history/${id}/send-mail/`;
  const response = await axios.post(url, {}, options);
  return response;
}

export async function sendCorporateInvoicePdf(request, id, corporateId) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
    responseType: 'arraybuffer',
  };

  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/corporate-invoice/send-mail?corporateId=${corporateId}&invoiceNumber=${id}`;
  const response = await axios.post(url, {}, options);
  return response;
}

export async function sendCorporateLumpSumInvoicePdf(request, id, corporateId) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
    responseType: 'arraybuffer',
  };

  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/corporate-lumpsum-invoice/send-mail?corporateId=${corporateId}&invoiceNumber=${id}`;
  const response = await axios.post(url, {}, options);
  return response;
}

export async function downloadContractPdf(request, id) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
    responseType: 'arraybuffer',
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/student/contract/${id}/pdf`;
  const response = await axios.get(url, options);
  return response;
}

export async function downloadCoupon(request) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${request.resource}`;
  const response = await axios.get(url, options);
  return response;
}
export async function downloadTrainingSheet(request, id, code) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
    responseType: 'arraybuffer',
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  let url = `${API_DOMAIN}/user/v1/student-training-sheet/${id}?code=${code}`;
  if (request.date) {
    url += `&startDate=${request.date}`;
  }
  const response = await axios.get(url, options);
  return response;
}

export async function getVideoThumb(request) {
  // console.log('url: ', request);
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${request.resource}`;
  // console.log('url: ', request);
  const response = await axios.get(url, options);
  return response;
}

export async function updatePrimaryCategory(request, data) {
  const payload = data;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  if (payload.__v) {
    delete payload.__v;
    delete payload.updatedAt;
  }
  const url = `${API_DOMAIN}/${request.resource}`;
  const response = await axios.put(url, data, options);
  return response;
}

export async function updateEmiratesDetails(data, id) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/student/${id}/update-emirates-details`;
  const response = await axios.put(url, data, options);
  return response;
}

export async function getAdditionalFees(request, addonId, isLumpsumAddon) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  let url = `${API_DOMAIN}/${request.resource}`;
  if (addonId && !isLumpsumAddon) {
    url += `?addonId=${addonId}`;
  }

  const response = await axios.get(url, options);
  return response;
}

export async function additionalPayment(token, data, params = null) {
  let url = `${API_DOMAIN}/user/v1/additional-fees`;
  if (params && params.id) {
    url += `/${params.id}`;
  }
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }
  const response = await axios.post(url, data, options);
  return response;
}

export async function suggestPracticalTraining(token, data, params = null) {
  let url = `${API_DOMAIN}/user/v1/suggest-additional-training`;
  if (params && params.id) {
    url += `/${params.id}`;
  }
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }
  const response = await axios.post(url, data, options);
  return response;
}

export async function createSuggestPracticalTrainingRequest(
  token,
  data,
  params = null
) {
  let url = `${API_DOMAIN}/user/v1/create-suggest-additional-training-request`;
  if (params && params.id) {
    url += `/${params.id}`;
  }
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }
  const response = await axios.post(url, data, options);
  return response;
}

export async function revokeCancellationCharges(token, data, params = null) {
  const url = `${API_DOMAIN}/user/v1/revoke-cancellation-charges`;

  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }
  const response = await axios.post(url, data, options);
  return response;
}

export async function saveContract(data, id) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/student/contract/${id}`;
  const response = await axios.put(url, data, options);
  return response;
}

export async function createWithId(request, data) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  const url = `${API_DOMAIN}/${request.resource}/${request.resourceId}`;
  const response = await axios.post(url, data, options);
  return response;
}

export async function skipCourseStage(data, studentId) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/student/course/stage/skip/${studentId}`;
  const response = await axios.post(url, data, options);
  return response;
}

export async function getInstructorAvailability(resource) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${resource}`;

  const response = await axios.get(url, options);
  return { data: response.data, count: response.headers['x-total-count'] };
}

export async function getBySlot(resource) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${resource}`;

  const response = await axios.get(url, options);
  return response.data;
}

export async function downloafdList(request) {
  // ?_sort=name&_order=ASC&_filters={"name": "kumar"}
  const { field, order } = request.sort;
  const { page = 1, perPage = pageSize } = request.pagination;

  const query = {
    ...request.queryParams,
    _filters: JSON.stringify(request.filter),
    _sort: field,
    _order: order,
    _start: (page - 1) * perPage,
    _end: page * perPage,
  };

  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${request.resource}?${stringify(query)}`;

  const response = await axios.get(url, options);
  return {
    data: response.data,
  };
}

export async function getDailyReport() {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/admin/daily-report`;

  const response = await axios.get(url, options);
  return { data: response.data };
}
export async function getCtsSanityLogList(resource) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${resource}`;

  const response = await axios.get(url, options);
  return { data: response.data, count: response.headers['x-total-count'] };
}

export async function getRegistrationReport(
  startDate,
  endDate,
  licenseTypeId,
  branchId
) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/admin/registration-report?startDate=${startDate}&endDate=${endDate}&licenseTypeId=${licenseTypeId}&branchId=${branchId}`;

  const response = await axios.get(url, options);
  return { data: response.data };
}

export async function getInstructorUtilizationReport(
  startDate,
  endDate,
  licenseTypeId,
  branchId,
  permitType,
  instructorTypeId
) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  let url = `${API_DOMAIN}/schedule/v1/availability/utilization/summary?licenseTypeId=${licenseTypeId}`;
  if (startDate) {
    url += `&startDate=${startDate}`;
  }
  if (endDate) {
    url += `&endDate=${endDate}`;
  }
  if (permitType) {
    url += `&permitType=${permitType}`;
  }
  if (branchId) {
    url += `&branchId=${branchId}`;
  }
  if (instructorTypeId) {
    url += `&instructorTypeId=${instructorTypeId}`;
  }
  const response = await axios.get(url, options);
  return { data: response.data };
}

export async function downloadRegistrationReport(
  startDate,
  endDate,
  licenseTypeId,
  branchId
) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  const url = `${API_DOMAIN}/user/v1/admin/registration-report/download?startDate=${startDate}&endDate=${endDate}&licenseTypeId=${licenseTypeId}&branchId=${branchId}`;

  const response = await axios.get(url, options);
  return { data: response.data };
}

export async function getTestReport(filter) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/admin/test-report?filter=${JSON.stringify(
    filter
  )}`;

  const response = await axios.get(url, options);
  return { data: response.data };
}

export async function getInstructorApiReport(request, filter) {
  const { field, order } = request.sort;
  const { page = 1, perPage = pageSize } = request.pagination;

  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  // const url = `${API_DOMAIN}/user/v1/admin/test-report?filter=${JSON.stringify(
  //   filter
  // )}`;
  const query = {
    // ...request.queryParams,
    _filters: JSON.stringify(filter),
    _sort: field,
    _order: order,
    _start: (page - 1) * perPage,
    _end: page * perPage,
    // _getcount: getcount,
  };
  const url = `${API_DOMAIN}/analytics/v1/api-access-logs/instructor-apis?${stringify(
    query
  )}`;

  const response = await axios.get(url, options);
  return { data: response.data };
}
export async function getInstructorApiForListing(request, filter) {
  const { field, order } = request.sort;
  const { page = 1, perPage = pageSize } = request.pagination;

  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  // const url = `${API_DOMAIN}/user/v1/admin/test-report?filter=${JSON.stringify(
  //   filter
  // )}`;
  const query = {
    // ...request.queryParams,
    _filters: JSON.stringify(filter),
    _sort: field,
    _order: order,
    _start: (page - 1) * perPage,
    _end: page * perPage,
    // _getcount: getcount,
  };
  const url = `${API_DOMAIN}/analytics/v1/api-access-logs/instructor-apis/list?${stringify(
    query
  )}`;

  const response = await axios.get(url, options);
  return { data: response.data, count: response.headers['x-total-count'] };
}
export async function getStudentDetails(filter) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/instructors/get-incentive?_filters=${JSON.stringify(
    filter
  )}`;

  const response = await axios.get(url, options);
  return { data: response.data };
}
export async function getMockTestReport(filter) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  const url = `${API_DOMAIN}/user/v1/mock-test-report?filter=${JSON.stringify(
    filter
  )}`;
  const response = await axios.get(url, options);
  return { data: response.data };
}

export async function getProcessedIncentiveDetails(filter) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  const url = `${API_DOMAIN}/user/v1/instructors/get-incentive-processed?_filters=${JSON.stringify(
    filter
  )}`;

  const response = await axios.get(url, options);
  return { data: response.data };
}

export async function downloadTestReport(filter) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/admin/test-report/download?filter=${JSON.stringify(
    filter
  )}`;

  const response = await axios.get(url, options);
  return { data: response.data };
}

export async function downloadMockTestReport(filter) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/mock-test-report/download?filter=${JSON.stringify(
    filter
  )}`;

  const response = await axios.get(url, options);
  return { data: response.data };
}

export async function downloadApiReport(request) {
  const { field, order } = request.sort;
  const { page = 1, perPage = pageSize } = request.pagination;

  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const query = {
    // ...request.queryParams,
    _filters: JSON.stringify(request.filter),
    _sort: field,
    _order: order,
    // _start: (page - 1) * perPage,
    // _end: page * perPage,
    // _getcount: getcount,
  };

  const url = `${API_DOMAIN}/analytics/v1/api-access-logs/instructor-apis/list/download?${stringify(
    query
  )}`;

  const response = await axios.get(url, options);
  return { data: response.data };
}

export async function downloadStudent(request, subject) {
  const { field, order } = request.sort;

  const query = {
    ...request.queryParams,
    _filters: JSON.stringify(request.filter),
    _sort: field,
    _order: order,
  };
  if (subject) {
    query.subject = subject;
  }
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${request.resource}?${stringify(query)}`;

  const response = await axios.get(url, options);
  return {
    data: response.data,
  };
}

export async function getAsbsentClass(request) {
  // ?_sort=name&_order=ASC&_filters={"name": "kumar"}
  const { page = 1, perPage = pageSize } = request.pagination;
  const { field, order } = request.sort;

  const query = {
    _sort: field,
    _order: order,
    _start: (page - 1) * perPage,
    _end: page * perPage,
  };
  if (request.filter.startDate) {
    query.startDate = getUtcMidnight(
      new Date(request.filter.startDate)
    ).toISOString();
  }
  if (request.filter.endDate) {
    query.endDate = getUtcMidnight(
      new Date(request.filter.endDate)
    ).toISOString();
  }
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${request.resource}?${stringify(query)}`;

  const response = await axios.get(url, options);
  return {
    data: response.data,
    count: response.headers['x-total-count'],
    pendingCount: response.headers['x-pending-count'],
    totalAmount: response.headers['x-total-amount'],
    totalCardAmount: response.headers['x-total-card'],
    totalCashAmount: response.headers['x-total-cash'],
    totalOnlineAmount: response.headers['x-total-online'],
  };
}
export async function getAssessmentResultDetails({
  assessmentId,
  instanceCode,
}) {
  const request = {
    resource: 'curriculum/v1/assessment-results',
    pagination: {},
    sort: {},
    resourceId: assessmentId,
    token: store.getState().authUser.token,
  };

  const query = {
    instanceCode,
  };

  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${request.token}`,
    },
  };

  const url = `${API_DOMAIN}/${request.resource}/${
    request.resourceId
  }?${stringify(query)}`;

  const { data } = await axios.get(url, options);

  return data;
}

export async function getCourseStructure(request) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${request.resource}?licenseTypeId=${request.licenseTypeId}`;

  const response = await axios.get(url, options);
  return {
    data: response.data,
  };
}

export async function getCustomCourses(request, data) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${request.resource}`;

  const response = await axios.post(url, data, options);
  return {
    data: response.data,
  };
}

export async function downloadRefundDocument(id, isCorporate) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const link = isCorporate ? 'corporate-refund' : 'refund';
  const url = `${API_DOMAIN}/user/v1/student/${link}/${id}/pdf`;
  const response = await axios.get(url, options);
  return response;
}

export async function downloadClearanceCertificateDocument(id) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/student/clearance-certificate/${id}/pdf`;
  const response = await axios.get(url, options);
  return response;
}
export async function getAppointmentSettings() {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  const url = `${API_DOMAIN}/entity/v1/appointment-settings`;
  const response = await axios.get(url, options);
  return { data: response.data };
}

export async function issueLicense(studentId) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/student/course/license-issue/${studentId}`;
  const response = await axios.post(url, {}, options);
  return response;
}

export async function unlinkPhone(studentId) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/admin/student/unlink-phone/${studentId}`;
  const response = await axios.post(url, {}, options);
  return response;
}

export async function getCouponCount(request) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${request.resource}`;
  // console.log('url: ', request);
  const response = await axios.get(url, options);
  return response;
}

export async function getLectureHallBookings(resource) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  const url = `${API_DOMAIN}/${resource}`;

  const response = await axios.get(url, options);
  return { data: response.data, count: response.headers['x-total-count'] };
}

export async function getPendingIncentive(resource) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  const url = `${API_DOMAIN}/${resource}`;

  const response = await axios.get(url, options);
  return { data: response.data, count: response.headers['x-total-count'] };
}

export async function lectureMarkAsAttended(schedules, slotId) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/schedule/v1/lecture-hall/availability/${slotId}/bookings/mark-as-attended`;
  const response = await axios.post(url, { schedules }, options);
  return response;
}

export async function lectureMarkAsAbsent(schedules, slotId) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/schedule/v1/lecture-hall/availability/${slotId}/bookings/mark-as-absent`;
  const response = await axios.post(url, { schedules }, options);
  return response;
}

export async function postIncentives(instructorIds) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/instructors/post-incentive`;
  const response = await axios.post(url, { instructorIds }, options);
  return response;
}

export async function changeInstructor(availabilityId, instructorId) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/schedule/v1/lecture-hall/availability/${availabilityId}/lecturer`;
  const response = await axios.put(
    url,
    {
      instructorId,
    },
    options
  );
  return response;
}

export async function downloadStudentList(
  startDate,
  endDate,
  licenseTypeId,
  branchId,
  corporateId,
  studentStatus,
  stageCode,
  studentType,
  courseTypeId,
  reportType
) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  let url = `${API_DOMAIN}/user/v1/admin/student/report/download?startDate=${startDate}&endDate=${endDate}`;
  if (corporateId) {
    url += `&corporateId=${corporateId}`;
  }
  if (licenseTypeId) {
    url += `&licenseTypeId=${licenseTypeId}`;
  }
  if (branchId) {
    url += `&branchId=${branchId}`;
  }
  if (studentStatus) {
    url += `&studentStatus=${studentStatus}`;
  }
  if (stageCode) {
    url += `&stageCode=${stageCode}`;
  }

  if (studentType) {
    url += `&studentType=${studentType}`;
  }

  if (courseTypeId) {
    url += `&courseTypeId=${courseTypeId}`;
  }

  if (reportType) {
    url += `&reportType=${reportType}`;
  }
  const response = await axios.get(url, options);
  return response;
}

export async function downloadTestList(
  startDate,
  endDate,
  testType,
  licenseTypeId,
  branchId,
  testBranchCode
) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  let url = `${API_DOMAIN}/user/v1/admin/test-schedule/report/download?startDate=${startDate}&endDate=${endDate}`;
  if (testType) {
    url += `&testType=${testType}`;
  }
  if (licenseTypeId) {
    url += `&licenseTypeId=${licenseTypeId}`;
  }
  if (branchId) {
    url += `&branchId=${branchId}`;
  }
  if (testBranchCode) {
    url += `&testBranchCode=${testBranchCode}`;
  }
  const response = await axios.get(url, options);
  return response;
}

export async function downloadAssessmentList(
  startDate,
  endDate,
  testType,
  licenseTypeId
) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  let url = `${API_DOMAIN}/user/v1/admin/assessment-schedule/report/download?startDate=${startDate}&endDate=${endDate}`;
  if (testType) {
    url += `&testType=${testType}`;
  }
  if (licenseTypeId) {
    url += `&licenseTypeId=${licenseTypeId}`;
  }
  const response = await axios.get(url, options);
  return response;
}

export async function downloadExaminerPerformance(
  startDate,
  endDate,
  testType,
  licenseTypeId
) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  let url = `${API_DOMAIN}/user/v1/admin/examiner-performance/report/download?startDate=${startDate}&endDate=${endDate}`;
  if (testType) {
    url += `&testType=${testType}`;
  }
  if (licenseTypeId) {
    url += `&licenseTypeId=${licenseTypeId}`;
  }
  const response = await axios.get(url, options);
  return response;
}

export async function downloadInstructorReport(
  startDate,
  endDate,
  gender,
  licenseTypeId,
  branchId,
  permitType
) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  let url = `${API_DOMAIN}/user/v1/admin/instructor-utilization/report/download?startDate=${startDate}&endDate=${endDate}`;
  if (gender) {
    url += `&gender=${gender}`;
  }
  if (licenseTypeId) {
    url += `&licenseTypeId=${licenseTypeId}`;
  }
  if (permitType) {
    url += `&permitType=${permitType}`;
  }
  if (branchId) {
    url += `&branchId=${branchId}`;
  }
  const response = await axios.get(url, options);
  return response;
}

export async function updateRemaining(request, data) {
  const payload = data;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  if (payload.__v) {
    delete payload.__v;
    delete payload.updatedAt;
  }
  const url = `${API_DOMAIN}/${request.resource}`;
  const response = await axios.put(url, data, options);
  return response;
}

export async function getCorporateInvoice(request) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${request.resource}`;
  const response = await axios.get(url, options);
  return response;
}

export async function downloadLectureAttendance(
  startDate,
  endDate,
  licenseTypeId
) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  let url = `${API_DOMAIN}/user/v1/admin/lecture-attendance/report/download?startDate=${startDate}&endDate=${endDate}`;
  if (licenseTypeId) {
    url += `&licenseTypeId=${licenseTypeId}`;
  }
  const response = await axios.get(url, options);
  return response;
}

export async function downloadStudentListWithoutLearningPermitNumber(
  startDate,
  endDate,
  isRtaFileIdExists
) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  const url = `${API_DOMAIN}/user/v1/admin/student/report/download?startDate=${startDate}&endDate=${endDate}&isRtaFileIdExists=${isRtaFileIdExists}`;
  const response = await axios.get(url, options);
  return response;
}

export async function downloadOnlineLectureAttendance(
  date,
  learningPermitNumber,
  licenseTypeId
) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  let url = `${API_DOMAIN}/user/v1/admin/online-lecture-attendance/report/download?date=${date}`;
  if (learningPermitNumber) {
    url += `&learningPermitNumber=${learningPermitNumber}`;
  }
  if (licenseTypeId) {
    url += `&licenseTypeId=${licenseTypeId}`;
  }
  const response = await axios.get(url, options);
  return response;
}

export async function changeGearType(id, data) {
  const payload = data;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  if (payload.__v) {
    delete payload.__v;
    delete payload.updatedAt;
  }
  const url = `${API_DOMAIN}/user/v1/admin/gear-type-change/${id}`;
  const response = await axios.post(url, data, options);
  return response;
}

export async function changeGender(id, data) {
  const payload = data;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  if (payload.__v) {
    delete payload.__v;
    delete payload.updatedAt;
  }

  const url = `${API_DOMAIN}/user/v1/admin/gender-change/${id}`;
  const response = await axios.post(url, data, options);
  return response;
}

export async function getAddionalAddOnList(studentId) {
  const request = {
    resource: 'user/v1/student/additional-addon',
    pagination: {},
    sort: {},
    token: store.getState().authUser.token,
  };

  const query = {};

  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${request.token}`,
    },
  };

  const url = `${API_DOMAIN}/${request.resource}/${studentId}?${stringify(
    query
  )}`;
  // console.log('url is', url);
  const { data } = await axios.get(url, options);

  return data;
}

export async function getAddedAddOnList(studentId, corporateId) {
  const request = {
    resource: `user/v1/corporate/${corporateId}/invoice`,
    pagination: {},
    sort: {},
    token: store.getState().authUser.token,
  };
  const query = {
    _filters: JSON.stringify({ corporateId, userId: studentId, isAddon: true }),
  };

  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${request.token}`,
    },
  };

  const url = `${API_DOMAIN}/${request.resource}?${stringify(query)}`;
  // console.log('url is', url);
  const { data } = await axios.get(url, options);

  return data;
}

export async function getCheckBranchAvailability(request, dataToSend) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      branchId: dataToSend.branchId,
      date: dataToSend.date,
    },
  };

  const query = {
    ...request.queryParams,
    _filters: JSON.stringify(dataToSend),
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;

  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  const url = `${API_DOMAIN}/${request.resource}`;

  // console.log('url: ', request);
  const response = await axios.get(url, options);
  return response;
}

export async function changeInstructorBranch(request, data) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${request.resource}`;
  const response = await axios.post(url, data, options);
  return response;
}
export async function removeBranch(request) {
  console.log('removeBranch payload', request);

  const options = {
    headers: {},
    data: {},
  };

  const url = `${API_DOMAIN}/${request.resource}`;
  // console.log('url: ', request);
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  options.data = request.payload;
  const response = await axios.delete(url, options);
  return response;
}

export async function downloadInstructorUtilizationReport(
  startDate,
  endDate,
  licenseTypeId,
  branchId,
  permitType,
  instructorTypeId
) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  let url = `${API_DOMAIN}/schedule/v1/availability/utilization/summary/download?licenseTypeId=${licenseTypeId}`;
  if (startDate) {
    url += `&startDate=${startDate}`;
  }
  if (endDate) {
    url += `&endDate=${endDate}`;
  }
  if (permitType) {
    url += `&permitType=${permitType}`;
  }
  if (branchId) {
    url += `&branchId=${branchId}`;
  }
  if (instructorTypeId) {
    url += `&instructorTypeId=${instructorTypeId}`;
  }
  const response = await axios.get(url, options);
  return { data: response.data };
}

export async function applyReferral(data, token) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }
  const url = `${API_DOMAIN}/user/v1/apply-referral`;
  return axios.post(url, data, options);
}

export async function removeReferral(data, token) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }
  const url = `${API_DOMAIN}/user/v1/remove-referral`;
  return axios.post(url, data, options);
}

export async function cancellReferral(data, token) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }
  const url = `${API_DOMAIN}/user/v1/cancell-referral`;
  return axios.post(url, data, options);
}

export async function getUserReferralReport(filter, user) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/referral-bonus/user/${user}?_filters=${JSON.stringify(
    filter
  )}`;

  const response = await axios.get(url, options);
  return { data: response.data };
}

export async function downloadUserReferralReport(filter, user) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/referral-bonus/user/download/${user}?_filters=${JSON.stringify(
    filter
  )}`;

  const response = await axios.get(url, options);
  return { data: response.data };
}

export async function downloadCustomersReferalBonusDetailReport(id) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/referral-bonus/customers/download/${id}`;

  const response = await axios.get(url, options);
  return { data: response.data };
}

export async function downloadReferedCustomersReferalBonusDetailReport(id) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/referral-bonus/referred-customers/download/${id}`;

  const response = await axios.get(url, options);
  return { data: response.data };
}

export async function initiateQuickSignUpPayment(token, data, params = null) {
  let url = `${API_DOMAIN}/user/v2/student/pre-registartion/payment`;
  if (params && params.id) {
    url += `/${params.id}`;
  }
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }
  const response = await axios.post(url, data, options);
  return response;
}

export async function getAdvancePaymentInvoice(studentId) {
  const request = {
    resource: 'user/v1/student/advance-payment-invoice',
    token: store.getState().authUser.token,
  };

  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${request.token}`,
    },
  };

  const url = `${API_DOMAIN}/${request.resource}/${studentId}`;
  const { data } = await axios.get(url, options);
  return data;
}

export async function getAUserWalletBalance(studentId) {
  const request = {
    resource: 'user/v1/student/user-wallet-balance',
    token: store.getState().authUser.token,
  };

  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${request.token}`,
    },
  };

  const url = `${API_DOMAIN}/${request.resource}/${studentId}`;
  const { data } = await axios.get(url, options);
  return data;
}

export async function cancelPreregistrationInvoice(request, data, refundType) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const body = {
    refundType,
  };
  const url = `${API_DOMAIN}/user/v1/student/preregistartion-cancel-invoice/${data._id}`;
  const response = await axios.post(url, body, options);
  return response;
}

export async function getReferralWithdrawDetails(request, filter) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${request.resource}/${
    request.resourceId
  }?_filters=${JSON.stringify(filter)}`;
  // console.log('url: ', request);
  const response = await axios.get(url, options);
  return response;
}

export async function getReferralReportConfiguration() {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  const url = `${API_DOMAIN}/user/v1/referral-bonus/configure`;
  const response = await axios.get(url, options);
  return { data: response.data };
}

export async function addPassengerToTrip(request, data) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${request.resource}`;
  const response = await axios.post(url, data, options);
  return response;
}

export async function updateTrips(request, data) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${request.resource}`;
  const response = await axios.put(url, data, options);
  return response;
}

export async function getTripsData(resource) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${resource}`;

  const response = await axios.get(url, options);
  return { data: response.data, count: response.headers['x-total-count'] };
}

export async function getEmiratesIdData() {
  try {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        mode: 'no-cors',
        'Access-Control-Allow-Origin': '*',
      },
      crossDomain: true,
    };
    const url = `http://localhost:9060/api/eidreader`;
    const response = await axios.get(url, options);
    console.log('response', response);
    return response.data;
  } catch (errors) {
    console.error('errors', errors);
  }
}

export async function deleteOffTimeById(request, document) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  options.data = {
    document,
  };
  const url = `${API_DOMAIN}/${request.resource}/${request.resourceId}`;
  const response = await axios.delete(url, options);
  return response;
}

export async function getTripPassengers(request) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${request.resource}`;
  // console.log('url: ', request);
  const response = await axios.get(url, options);
  return response;
}

export async function saveCompanyContract(data, id) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/entity/v1/company/contract/${id}`;
  const response = await axios.put(url, data, options);
  return response;
}

export async function updateCourseTypes(request) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  const url = `${API_DOMAIN}/${request.resource}`;
  const response = await axios.put(url, {}, options);
  return response;
}

export async function revokeAbsenteeismAddon(token, data) {
  const url = `${API_DOMAIN}/user/v1/additional-fees/revoke-limited-addon`;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }
  const response = await axios.post(url, data, options);
  return response;
}

export async function absenteeismAdditionalPayment(token, data, params = null) {
  let url = `${API_DOMAIN}/user/v1/additional-fees/collect-absenteeism-addons`;
  if (data?.isRTAPaymentAddon) {
    url = `${API_DOMAIN}/user/v1/additional-fees/collect-rta-addons`;
  }
  if (params && params.id) {
    url += `/${params.id}`;
  }
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }
  const response = await axios.post(url, data, options);
  return response;
}

export async function getRenewedDate(request, data) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/get-renewed-date`;
  const response = await axios.post(url, data, options);
  return response;
}

export async function getTicketIfExist(request) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${request.resource}?_filters=${JSON.stringify(
    request.filter
  )}`;
  const response = await axios.get(url, options);
  return response;
}

export async function createTicket(request, data) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${request.resource}`;
  const response = await axios.post(url, data, options);
  return response;
}

export async function updateTicket(request, data) {
  const payload = data;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  if (payload.__v) {
    delete payload.__v;
    delete payload.updatedAt;
  }
  const url = `${API_DOMAIN}/${request.resource}/${request.resourceId}`;
  const response = await axios.put(url, data, options);
  return response;
}

export async function getTicketById(request) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${request.resource}/${request.ticketId}`;
  const response = await axios.get(url, options);
  return response;
}

export async function getSearchInput(request) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${request.resource}`;
  const response = await axios.get(url, options);
  return response;
}

export async function getNewRegistrationsByDate(filters) {
  // const { page = 1, perPage = pageSize } = filters.pagination;
  // const { field, order } = filters.sort;

  const query = {
    _filters: JSON.stringify(filters.filter),
    // _sort: field,
    // _order: order,
    // _start: (page - 1) * perPage,
    // _end: page * perPage,
    // _getcount: getcount,
  };

  const request = {
    resource: 'user/v1/student/new-registrations',
    token: store.getState().authUser.token,
  };

  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${request.token}`,
    },
  };

  const url = `${API_DOMAIN}/${request.resource}?${query}`;
  const { data } = await axios.get(url, options);
  return data;
}

export async function downloadNewRegisteredCustomerReport(filters) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/student/new-registrations/download`;

  const response = await axios.post(url, filters, options);
  return { data: response.data };
}

export async function getTrainingStudentList(request) {
  const { page = 1, perPage = pageSize } = request.pagination;
  const { field, order } = request.sort;

  const query = {
    _filters: JSON.stringify(request.filter),
    _sort: field,
    _order: order,
    _start: (page - 1) * perPage,
    _end: page * perPage,
  };

  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  let url = `${API_DOMAIN}/${request.resource}`;

  if (query) {
    url += `?${stringify(query)}`;
  }

  const response = await axios.get(url, options);
  return { data: response.data, count: response.headers['x-total-count'] };
}

export async function lectureMarkAsTrainingAttended(schedules, slotId) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/schedule/v1/lecture-hall/availability/${slotId}/bookings/mark-as-training-attended`;
  const response = await axios.post(url, { schedules }, options);
  return response;
}

export async function lectureMarkAsTrainingAbsent(schedules, slotId) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/schedule/v1/lecture-hall/availability/${slotId}/bookings/mark-as-training-absent`;
  const response = await axios.post(url, { schedules }, options);
  return response;
}

export async function updateTabbyDetails(data, token) {
  const url = `${API_DOMAIN}/user/v2/student/updateTabbyCharge`;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }
  return axios.post(url, data, options);
}

export async function updateNonTabbyDetails(data, token) {
  const url = `${API_DOMAIN}/user/v2/student/updateNonTabbyCharge`;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }
  return axios.post(url, data, options);
}

export async function getTabbyConfiguration() {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v2/student/getTabbyConfiguration`;
  const response = await axios.get(url, options);
  return response.data;
}

export async function downloadCollectionReport(filter) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/csr/collection-report/pdf?filter=${JSON.stringify(
    filter
  )}`;

  const response = await axios.get(url, options);
  return { data: response.data };
}

export async function downloadCollectionReportPdf(request, filters) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
    responseType: 'arraybuffer',
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/salesman/collection-report/pdf`;
  const response = await axios.post(url, filters, options);
  return response;
}

export async function getSalesmanCollectionReport(filter) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/salesman/salesman-collection-report?filter=${JSON.stringify(
    filter
  )}`;
  const response = await axios.get(url, options);
  return { data: response.data };
}

export async function downloadSalesmanCollectionReport(filter) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/salesman/collection-report/download?filter=${JSON.stringify(
    filter
  )}`;
  const response = await axios.get(url, options);
  return { data: response.data };
}

export async function downloadCollectedInvoices(request, subject) {
  const { field, order } = request.sort;
  const query = {
    ...request.queryParams,
    _filters: JSON.stringify(request.filter),
    _sort: field,
    _order: order,
  };
  if (subject) {
    query.subject = subject;
  }
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${request.resource}?${stringify(query)}`;
  const response = await axios.get(url, options);
  return {
    data: response.data,
  };
}

export async function floatAmountUpdates(request, data) {
  const payload = data;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  if (payload.__v) {
    delete payload.__v;
    delete payload.updatedAt;
  }
  try {
    const url = `${API_DOMAIN}/${request.resource}/${request.resourceId}`;
    const response = await axios.put(url, data, options);
    // const response = {
    //   data : {
    //     _id : '65d62a6cb61ede007d592454',
    //   }
    // };
    toastr.success('Success', 'Amount collected');
    if (response?.data) {
      const downloadResponse = await downloadSalesmanCollectingReport(
        {},
        response?.data?._id
      );
      const responseData = new Blob([downloadResponse.data], {
        type: 'application/pdf',
      });
      saveAs(responseData, `collectingReport${Date.now()}.pdf`);
    }
    return response;
  } catch (error) {
    toastr.error(
      'Error',
      (error &&
        error.response &&
        error.response.data &&
        error.response.data.message) ||
        'Failed to download pdf'
    );
  }
}

export async function downloadSalesmanCollectingReport(
  request,
  collectedResponseId
) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
    responseType: 'arraybuffer',
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/salesman/collecting-report/${collectedResponseId}/download`;
  const response = await axios.get(url, options);
  return response;
}

export async function downloadAllCollectionReport(filter) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/all-collection-report/download?_filters=${JSON.stringify(
    filter
  )}`;
  const response = await axios.get(url, options);
  return { data: response.data };
}

export async function getStudentPendingAddonAmount(studentId) {
  const request = {
    resource: 'user/v1/additional-fees/addon-amount-due',
    pagination: {},
    sort: {},
    token: store.getState().authUser.token,
  };

  const query = {};

  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${request.token}`,
    },
  };

  const url = `${API_DOMAIN}/${request.resource}/${studentId}?${stringify(
    query
  )}`;
  const { data } = await axios.get(url, options);

  return data;
}

export async function downloadAllCollectionReportAsExel(filter) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/all-collection-report/downloadAsExcel?_filters=${JSON.stringify(
    filter
  )}`;
  const response = await axios.get(url, options);
  return { data: response.data };
}

export async function getSystemLogs(resource) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${resource}`;
  const response = await axios.get(url, options);
  return { data: response, count: response.headers['x-total-count'] };
}

export async function getPendingScheduleListing(resource) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${resource}`;
  const response = await axios.get(url, options);
  return { data: response.data, count: response.headers['x-total-count'] };
}
export async function getPendingScheduleListingWithFilters(filter) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/schedule/v1/schedule/pending?_filters=${JSON.stringify(
    filter
  )}`;
  const response = await axios.get(url, options);
  return { data: response.data };
}
export async function markScheduleAsComplete(scheduleIds) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/schedule/v1/schedule/mark-schedules-as-completed`;
  const response = await axios.post(url, { scheduleIds }, options);
  return response;
}

export async function getStudentApprovalRequests(studentId) {
  const request = {
    resource: 'entity/v1/approval-matrix/requests',
    pagination: {},
    sort: {},
    token: store.getState().authUser.token,
  };

  const query = {
    user: studentId,
  };

  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${request.token}`,
    },
  };

  const url = `${API_DOMAIN}/${request.resource}?${stringify(query)}`;
  // console.log('url is', url);
  const { data } = await axios.get(url, options);

  return data;
}

export async function approveOrRejectFullCourseRefundRequest(
  id,
  reason,
  type,
  token
) {
  const url = `${API_DOMAIN}/entity/v1/approval-matrix/requests/full-course-refund/${id}/${type}`;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  } else {
    const userToken = store.getState().authUser.token;
    if (userToken) {
      options.headers.Authorization = `Bearer ${userToken}`;
    }
  }

  return axios.post(url, { reason }, options);
}

export async function approveOrRejectInvoiceRefundRequest(
  id,
  reason,
  type,
  token
) {
  const url = `${API_DOMAIN}/entity/v1/approval-matrix/requests/invoice-refund/${id}/${type}`;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  } else {
    const userToken = store.getState().authUser.token;
    if (userToken) {
      options.headers.Authorization = `Bearer ${userToken}`;
    }
  }

  return axios.post(url, { reason }, options);
}

export async function approveOrRejectClassCancellationRequest(
  id,
  reason,
  type,
  token
) {
  const url = `${API_DOMAIN}/entity/v1/approval-matrix/requests/class-cancellation/${id}/${type}`;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  } else {
    const userToken = store.getState().authUser.token;
    if (userToken) {
      options.headers.Authorization = `Bearer ${userToken}`;
    }
  }

  return axios.post(url, { reason }, options);
}

export async function getApprovalRequests(queryData, filter) {
  const request = {
    resource: 'entity/v1/approval-matrix/requests',
    pagination: {},
    sort: {},
    token: store.getState().authUser.token,
  };

  const query = {
    ...queryData,
    _filters: JSON.stringify(filter),
  };

  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${request.token}`,
    },
  };

  const url = `${API_DOMAIN}/${request.resource}?${stringify(query)}`;
  // console.log('url is', url);
  const { data } = await axios.get(url, options);

  return data;
}

export async function approveOrRejectPromotionRequest(id, reason, type, token) {
  const url = `${API_DOMAIN}/entity/v1/approval-matrix/requests/promotion/${id}/${type}`;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  } else {
    const userToken = store.getState().authUser.token;
    if (userToken) {
      options.headers.Authorization = `Bearer ${userToken}`;
    }
  }

  return axios.post(url, { reason }, options);
}

export async function getGeneralSettingsDetails(settingsId) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  let url = `${API_DOMAIN}/entity/v1/app-settings`;
  if (settingsId) {
    url += `/${settingsId}`;
  }

  const response = await axios.get(url, options);
  return response?.data;
}

export async function sentFailedUtilizationsToErp(data) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/student-utilization/pushFailedData`;
  const response = await axios.post(url, data, options);
  return 'success';
}

export async function rtaPaymentCreditAddonPayment(token, data, params = null) {
  let url = `${API_DOMAIN}/user/v1/additional-fees/generate-rta-credit`;
  if (params && params.id) {
    url += `/${params.id}`;
  }
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }
  const response = await axios.post(url, data, options);
  return response;
}

export async function deleteContract(request) {
  const url = `${API_DOMAIN}/${request.resource}`;
  // console.log('url: ', request);
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const response = await axios.delete(url, options);
  return response;
}

export async function getAdditionalClassRequestsByStudentId(studentId) {
  const request = {
    resource: `user/additional-class-request`,
    pagination: {},
    sort: {},
    token: store.getState().authUser.token,
  };
  const query = {
    userId: studentId,
  };
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${request.token}`,
    },
  };
  const url = `${API_DOMAIN}/${request.resource}?${stringify(query)}`;
  // console.log('url is', url);
  const { data } = await axios.get(url, options);
  return data;
}

export async function approveOrRejectChangeInstructorRequest(
  id,
  reason,
  type,
  token
) {
  const url = `${API_DOMAIN}/entity/v1/approval-matrix/requests/change-instructor/${id}/${type}`;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  } else {
    const userToken = store.getState().authUser.token;
    if (userToken) {
      options.headers.Authorization = `Bearer ${userToken}`;
    }
  }

  return axios.post(url, { reason }, options);
}
